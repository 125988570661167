/**
 * @description Check and update the members only status on the page
 */
function checkMembersOnlyStatus() {
	var membersOnlyStatusElement = $('#memberStatus');
	if (!membersOnlyStatusElement.length) {
		return;
	}
	var statusData = membersOnlyStatusElement.data();
	if (!statusData.loggedin) {
		return;
	}
	var isFree = statusData.free;
	var isPremium = statusData.premium;
	var isMilitary = statusData.military;
	if (isFree) {
		$('.members-type-free').removeClass('locked');
	}
	if (isPremium) {
		$('.members-type-premium').removeClass('locked');
	}
	if (isMilitary) {
		$('.members-type-military').removeClass('locked');
	}
}
module.exports = {
	checkMembersOnlyStatus: checkMembersOnlyStatus
};
