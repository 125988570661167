'use strict';

var productTile = require('../product-tile'),
    progress = require('../progress'),
    util = require('../util'),
    product = require('./product/index'),
    imagesLoaded = require('imagesloaded');

function initCategoriesRefinements() {
    var urlPrice = new URLSearchParams(window.location.search);
    var priceUrl = urlPrice.get('pmin');
    if (priceUrl) {
        if ($('#resultToggle').length) {
            var resCount = parseInt($('#productres-toggle .hit-count span').text());
            $('#refinementsToggle .results-count span').text(resCount);
        }
        $('#search-results-main').toggleClass('refined-search');
        if ($('ul.color li').hasClass('selected')) {
            $('ul.checkable-values').addClass('category-refs');
        }
    }
    $('#category-level-1').find('.refinement-link.active').each(function () {
        var $item = $(this);
        if ($item.parents('li.refinement-link').find('.category-level-2 .refinement-link.active').length > 0) {
            $item.addClass('sub-categories-active');
        }
    });
    $('#search-results-main').removeClass('searhres-hide');
}

function loadMore() {
    var block = parseInt($("#search-results-main").attr('data-loadmore'));
    $(".search-results-content").each(function () {
        var element = $(this);
        //Loop all children elements of current element
         $(element).children().each(function () {
            $(element).find('.searchres-block').slice(0, block).show();
            if ($(element).children('.searchres-block:hidden').length || $(element).find('ul > li:not(:visible)').length) {
                $(element).find('.load-more-content').show();
            } else {
                $(element).find('.load-more-content').addClass('complete');
            }
        });
    })
}

function dateFormat() {
    $('.searchres-date').each(function () {
        var monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
        var dateDis = $(this).attr("data-date");
        var date = new Date(dateDis);
        var month = monthNames[date.getMonth()]
        var day = date.getDate();
        var year = date.getFullYear();
        var fullDate =  month + ' ' + day + ', ' + year;
        $(this).text(fullDate);
    });
}

function infiniteScroll() {
	var distance = ''
	if ($('.search-page').length > 0) {
		distance = 250;
	} else {
		distance = $('#search-result-items').outerHeight() - 1400;
	}
	// getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), distance)) {
		// switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');

        /**
         * named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
         */
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.removeClass('infinite-scroll-loading');
            loadingPlaceHolder.attr('data-loading-state', 'loaded');
            loadingPlaceHolder.addClass('complete');
            $('div.search-result-content').append(html);
            $('#search-results-main').removeClass('searhres-hide');
        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649
        if (false) {
            // if we hit the cache
            fillEndlessScrollChunk(sessionStorage['scroll-cache_' + gridUrl]);
        } else {
            // else do query via ajax
            $.ajax({
                type: 'GET',
                dataType: 'html',
                url: gridUrl,
                cache: true,
				beforeSend: function () {
					if ($('.search-page').length > 0) {
						$('#search-result-items').addClass('loading-indicator');
					}
				},
                success: function (response) {
					$('#search-result-items').removeClass('loading-indicator');
                    // put response into cache
                    try {
                        sessionStorage['scroll-cache_' + gridUrl] = response;
                    } catch (e) {
                        // nothing to catch in case of out of memory of session storage
                        // it will fall back to load via ajax
                    }
                    // update UI
                    fillEndlessScrollChunk(response);
                    productTile.init();
                    $('#search-results-main').removeClass('searhres-hide');
                }
            });
        }
    }
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListingFull(url, focusElement, scrollPosition, filterStateText) {
    if (!url || url === window.location.href) {
        return;
    }
    progress.show($('.search-result-content'));
    var resCounts = [];
    if ($('body').data('rescounts') === undefined) {
        $('#resultToggle .counts-reg').each(function () {
            resCounts.push({
                title: $(this).data('searchtitle'),
                count:  $(this).data('searchcount')
            });
        });
    } else {
        resCounts = $('body').data('rescounts');
    }
    $('body').attr('data-rescounts', JSON.stringify(resCounts));
    $.ajax({
        type: 'GET',
        dataType: 'html',
        url: util.appendParamToURL(url, 'format', 'ajax'),
        cache: true,
        success: function (response) {
            $('#main').html(response);
            $('.desktop-refine-title').html(filterStateText);
            progress.hide();
            productTile.init();
            history.pushState(undefined, '', url);
            if (focusElement && focusElement.attr('id')) {
                document.getElementById(focusElement.attr('id')).focus();
            }
            imagesLoaded('.tiles-container').on('always', function () {
                if (util.getViewport() > 990) {
                    $('html, body').scrollTop($("#refined-by-attributes").offset().top + (-20));
                }
            });
            $.each(resCounts, function (index, value) {
                var target = $('#refine-toggles');
                var urlres = target.data('searchurl') + '&toggle=toggle-' + value.title.toLowerCase();
                target.append('<li class="content-selection appended-res"><a class="refinedcont-link" href="' + urlres + '"><i class="fa fa-square-o fa-lg selected-indicator"></i>' + value.title + '<span class="hit-count counts-reg">(<span>' + parseInt(value.count) + '</span>)</span></a></li>');
            });
            $('#search-results-main').removeClass('searhres-hide');
            initCategoriesRefinements();
            dateFormat();
            loadMore();
        }
    });
}
/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing (query, pop) {
    var refineUrl;

    if (typeof window.history.pushState === 'function' && typeof query === 'string') {
        if (query && ! pop) {
            refineUrl = window.location.pathname + '?' + query;
            window.history.pushState(query, $(document).find('title').text(), refineUrl);
        } else if (query && pop) {
            refineUrl = window.location.pathname + '?' + query;
        } else {
            refineUrl = window.location.pathname;
        }
    } else {
        var hash = location.href.split('#')[1];
        if (hash === 'results-content' || hash === 'results-products') { return; }

        if (typeof hash !== 'undefined' && hash.length > 0) {
            refineUrl = window.location.pathname + '?' + hash;
        } else {
            refineUrl = window.location.pathname;
        }
    }

    progress.show($('.search-result-content'));

    $('#main').load(util.appendParamToURL(refineUrl, 'format', 'ajax'), function () {
        productTile.init();
        progress.hide();
        loadMore();
        $('#search-results-main').removeClass('searhres-hide');
    });
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $main = $('#main');

	// add current scroll position to history.state
	$(document).on('click', '.pt_plp-page .thumb-link, .pt_plp-page .name-link, .pt_plp-page .swatch', function () {
		var currentTile = $(this).closest('.product-tile');
		var currentTilePosition = currentTile.offset();
		var currentScrollPosition = document.documentElement.scrollTop;
		var tileHeight = $('.grid-tile:not(.banner-grid)').eq(0).outerHeight();
		var bodyHeight = $('body').height();
		var url = window.location.href;
		url = util.removeParamFromURL(url, 'th');
		url = util.removeParamFromURL(url, 'bh');
		url = util.appendParamsToUrl(url, {'th': tileHeight, 'bh': bodyHeight, 'source': 'pdp'});

		window.history.pushState({scrollPosition: currentScrollPosition, currentTilePosition: currentTilePosition.top, bodyHeight: $('body').height(), tileHeight: $('.grid-tile').eq(0).css('height')}, $(document).find('title').text(), url);
	});

    if (util.getViewport() > 990) {
        if ($('body').hasClass('refinements-true')) {
            $('body').addClass('refinements-active');
        }
    }

    // handle show/hide refinements button click
    $main.on('click', '.load-more-content', function (e) {
        e.preventDefault();
        var block = parseInt($("#search-results-main").attr('data-loadmore'));
        var loadTarget = $(this).parents('.search-results-content').first().find('.searchres-block:hidden');
        loadTarget.slice(0, block).slideDown();
        if (loadTarget.length == 0) {
            $(this).addClass('complete');
        }
        return false;
    });

    // handle show/hide refinements button click
    $main.on('click', '#resultToggle .content-link', function (e) {
        e.preventDefault();
		var resultCount;
        var id = $(this).attr('id');
        var hitcount = $(this).find('span.hit-count').text();
        hitcount = parseInt(hitcount.replace(/[()]/g, ''));
        var intialTot = $('#refinementsToggle .results-count').data('resultcount');
        var currCheck = $(this).closest('.content-selection');
        $('body').removeClass('toggles-on');
        if (currCheck.hasClass('selected')) {
            currCheck.removeClass('selected');
            $('#search-results-main').removeClass();
        } else {
            $('.checkable-values .content-selection').removeClass('selected');
            currCheck.toggleClass('selected');
            if (id != 'toggle-products') {
                $('body').addClass('toggles-on');
            }
            $('#search-results-main').removeClass().addClass('toggle-refined').toggleClass(id);
        }
        if (currCheck.hasClass('selected')) {
            resultCount = hitcount;
        } else {
            resultCount = intialTot;
        }
        $('#refinementsToggle .results-count span').text(resultCount);
        $('#apply-filters .js-apply-filters__count').text(' ' + $('#resultToggle .content-selection.selected').length + ' ');
    });

	function resetRefinementsButtons() {
		var count = $('#refinementsToggle .results-count').data('resultcount');
		$('#search-results-main').removeClass();
		$('#resultToggle .content-selection.selected').removeClass('selected');
		$('#refinementsToggle .results-count span').text(count);
		$('#apply-filters .js-apply-filters__count').text(' 0 ');
		$('body').removeClass('toggles-on');
	}

    // handle show/hide refinements button click
    $main.on('click', '#refinementsToggle', function (e) {
        e.preventDefault();
        $('body').toggleClass('refinements-active');
        if ($('body').hasClass('mobile-true')) {
            $('body').removeClass('mobile-true');
        }

        if (util.getViewport() >= 880) {
            $(window).trigger('resize');
        }

        if (util.getViewport() < 990) {
            $(window).scrollTop($('.plp-actions').offset().top, 0);
        }
    });

    $main.on('click', '#apply-filters', function (e) {
        e.preventDefault();
        $('#refinementsToggle').trigger('click');

        if (util.getViewport() < 990) {
            $(window).scrollTop($('.plp-actions').offset().top, 0);
        }
    });

    // handle toggle refinement blocks
    $main.on('click', '.refinement h3', function () {
        $(this).toggleClass('expanded')
            .siblings('ul, .clear-refinement').toggle();
    });

    $main.on('click', '.plp-refinement-mobile-cta .button-clear-filter', function (e) {
        e.preventDefault();
        var $resetRefinementsLink = $('.clear-all-refinements a');
        if ($resetRefinementsLink.length < 1) {
			resetRefinementsButtons();
		}

        // Remove arrow animation after click
        var $btn = $(this);
        $btn.addClass('active');
        setTimeout(function () {
            $btn.removeClass('active');
        }, 500); // Duration of arrow animation

        $resetRefinementsLink.trigger('click');
    });

    // handle events for updating grid
    $main.on('click', '.refinement a.refinement-link, .color-refine, .pagination a, .selected-refinement-value a, .clear-all-refinements a', function (e) {
        if ($(this).parent().hasClass('unselectable')) { return; }
        var filterStateText = $('.desktop-refine-title').html();
        var scrollPosition = $(window).scrollTop();
        //if the anchor tag is underneath a div with the class names & , prevent the double encoding of the url
        //else handle the encoding for the url
        e.preventDefault();
        updateProductListingFull(this.href, $(this), scrollPosition, filterStateText);
    });

    // handle sorting change
    $main.on('change', '.sort-by select', function () {
        var refineUrl = $(this).find('option:selected').val();
        var uri = util.getUri(refineUrl);

        if (typeof window.history.pushState === 'function') {
            updateProductListing(uri.query.substring(1));
        } else {
            window.location.hash = uri.query.substring(1);
        }

        return false;
    })
        .on('change', '.items-per-page select', function () {
            var refineUrl = $(this).find('option:selected').val();
            if (refineUrl === 'INFINITE_SCROLL') {
                $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
            } else {
                $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
                var uri = util.getUri(refineUrl);

                if (typeof window.history.pushState === 'function') {
                    updateProductListing(uri.query.substring(1));
                } else {
                    window.location.hash = uri.query.substring(1);
                }
            }
            return false;
        });

    $('#main-banner-title').on('click', function () {
        if (util.getViewport() < 880) {
            $(this).parents('.banner-section-text').toggleClass('expanded');
        }
    });

    window.onload = function () {
        var urlParams = new URLSearchParams(window.location.search);
        var toggleUrl = urlParams.get('toggle');
        if (toggleUrl) {
            $('#' + toggleUrl).click();
        }
        $('#search-results-main').removeClass('searhres-hide');
    }

    // Detect when a user clicks the back button after the pushState function has been called
    if (typeof window.history.pushState === 'function') {
        // Safari fires popstate on load, hack to prevent firing this event on page load
        window.addEventListener('load', function () {
            setTimeout(function () {
                window.addEventListener('popstate', function (event) {
                    updateProductListing(event.state, true);
                });
            }, 0);
        });
    }

    // handle hash change
    window.onhashchange = updateProductListing;
}

exports.init = function () {
    var urlParams = util.getQueryStringParams(window.location.href);
	if (SitePreferences.LISTING_INFINITE_SCROLL) {
		if ($('.search-page').length > 0) {
			$('body').on('click', '.load-more', infiniteScroll);
		} else {
			$(window).on('scroll', infiniteScroll);
		}
	}
    productTile.init();
    product.init();
    initializeEvents();
    initCategoriesRefinements();
    dateFormat();
    loadMore();
    // If a hash is given update the product grid with the new parameters
    if (window.location.hash) {
        updateProductListing();
    }

    imagesLoaded('.tiles-container').on('always', function () {
		$('body').removeAttr('style');
		$('.tiles-container .grid-tile, .tiles-container .product-tile').css('min-height', '').css('height', '');
    });

    dataLayer.push({'event': 'optimize.activate'});
    console.log('optimize.activate');

    // Update Search Queries in Search Bar After Returning Results
    var searchParams = new URLSearchParams(window.location.search);
    var qParam = searchParams ? searchParams.get('q') : null;
    if (qParam) {
        $('input#q').val(qParam);
    }
};
