'use strict';

var imagery = require('./imagery'),
    dialog = require('../../dialog'),
    page = require('../../page'),
    util = require('../../util'),
    imagesLoaded = require('imagesloaded'),
    TPromise = require('promise'),
    imageSliders = require('../product/custom/imageSliders'),
    anchorScroll = require('../product/custom/anchorScroll'),
    p1Content = require('../product/custom/p1Content');

/**
 *
 */
var $currentTab = $('.configurator-controls').find('.config-tab.selected');
var $currentTabContent = $('.configurator-controls').find('.config-tab-content.open');
var tagCustomizer = $('.pdp-main.pdp-tag-customizer');
var isTagCustomizer = tagCustomizer.length > 0;
if (isTagCustomizer) {
    var configuratorControlselElement = tagCustomizer.find('.configurator-controls');
}

var controls = {
    init: function () {
        // A means of changing the Cart-AddProduct url on init of this page
        if (!window.hasOwnProperty('Urls')) {
            window.Urls = {};
        }
        window.Urls.addProduct = window.CustomizerResources.URL_ADD_PRODUCT;
        // If alert errors exist (even on page load), fire them now.
        launchConfigurationAlerts($('.configurator-controls'));

        $('.color-value').text($("input[name='headWearModelSelect'].configurator-option-select:checked, input[name='tagModelSelect'].configurator-option-select:checked").data('label'));
        $('#customizer-productID').text($("input[name='headWearModelSelect'].configurator-option-select:checked, input[name='tagModelSelect'].configurator-option-select:checked").attr('data-setvalue').trim());
        // For bag customizer only, hide personalization and enable add to cart on model select tab
        if ($('.config-master-tabs .config-tab.selected').is('#tagModel-tab')) {
            $('.tag-configurator-message-summary-line').hide();
            enableAddToCart();
        }
        if ($('.config-master-tabs .config-tab.selected').is('#messages-tab')) {
            $('.tag-configurator-message-summary-line').show();
            var currentText = configuratorControlselElement.find('.add-personalization-option-text').val();
            if (currentText.length === 0 || currentText.trim() === '') {
                disableAddToCart();
            }
        }

        // Tab control
        $('.configurator-controls').on('click', '.config-master-tabs .config-tab:not(.selected)', function () {
            if (isTagCustomizer) {
                var currentFont = configuratorControlselElement.attr('data-current-font');
                var currentColor = configuratorControlselElement.attr('data-current-color');
                var currentText = configuratorControlselElement.attr('data-current-text') ? configuratorControlselElement.attr('data-current-text') : configuratorControlselElement.find('.add-personalization-option-text').val();
                if ($('#tagModel-tab').hasClass('selected')) {
                    if (typeof currentColor === 'undefined' || currentColor === false) {
                        var selectedColor = configuratorControlselElement.find('.configurator-option.selected .configurator-option-select').attr('data-pid');
                        configuratorControlselElement.attr('data-current-color', selectedColor);
                    }
                } else {
                    if (typeof currentText !== 'undefined' && currentText !== false && currentText != '') {
                        configuratorControlselElement.attr('data-current-text', currentText);
                    }
                }
            }
            masterTabSwitch(this);
            var thisRef = this;
            // For bag customizer, clear out personalization and enable add to cart when going back to the model tab
            if ($(thisRef).is('#tagModel-tab')) {
                if ($('input.message-single-1').val().length > 0) {
                    var clearProductURL = window.CustomizerResources.URL_CLEAR_BALL;
                    if (typeof currentColor !== 'undefined' && currentColor !== false) {
                        var url = clearProductURL.replace(/(pid=)[^&]*/, '$1' + currentColor);
                        var clearProductURL = url;
                    }
                    clearCustomizedProduct(clearProductURL, function () {
                        updateAndRefreshControls(thisRef, true);
                        enableAddToCart();
                    });
                } else {
                    if (isTagCustomizer && $('#tagModel-tab').hasClass('selected')) {
                        imagery.gotoSlide('_01');
                    }
                }
            }
            // For bag customizer, only show personalization messaging on the personalization tab
            if ($(thisRef).is('#tagModel-tab')) {
                $('.tag-configurator-message-summary-line').hide();
            } else {
                $('.tag-configurator-message-summary-line').show();
            }
            if (isTagCustomizer) {
                if ($('#messages-tab').hasClass('selected')) {
                    if (typeof currentFont !== 'undefined' && currentFont !== false) {
                        configuratorControlselElement.find('.configurator-text-font-select').val(currentFont).change();
                    }
                    if (typeof currentText !== 'undefined' && currentText !== false && currentText != '') {
                        configuratorControlselElement.find('.add-personalization-option-text').val(currentText).blur();
                    } else {
                        configuratorControlselElement.find('.add-personalization-option-text').val('').blur();
                    }
                }
            }
        });

        // Bind event handler to configurator option <a> click
        $('.configurator-controls').on('click', '.configurator-option-select:not(input):not(.selected)', function (e) {
            e.preventDefault();
            updateAndRefreshControls(this, true);
        });

        // Bind event handler to configurator option radio button change
        $('.configurator-controls').on('change', 'input.configurator-option-select:not([checked])', function () {
            if (isTagCustomizer) {
                var currentColor = $(this).attr('data-pid');
                configuratorControlselElement.attr('data-current-color', currentColor);
            }
            updateAndRefreshControls(this, true);
        });
        $('.configurator-controls').on('change', '.configurator-text-font-select', function () {
            if (isTagCustomizer) {
                var currentFont = configuratorControlselElement.find('select.configurator-text-font-select').val();
                configuratorControlselElement.attr('data-current-font', currentFont);
            }
            updateAndRefreshControls(this, true);
        });

        if (!isTagCustomizer) {
            // Bind event handler to text input blur
            $('.configurator-controls').on('focus', '.configurator-text-inputs input', function () {
                imagery.gotoSlide($(this).data('face'));
            });
        }

        // Bind event handler to text input blur
        $('.configurator-controls').on('blur focusout', '.configurator-text-inputs input', function () {
            if (textFieldHasChanged(this) && validateMessageTextField(this)) {
                clearErrorMessage();
                if (validateCurrentTab()) {
                    clearTabAwayError();
                }
                submitTextFieldUpdate(this);
            }
        });

        // Bind event handler to text input change
        $('.configurator-controls').on('keyup input paste mouseup', '.configurator-text-inputs input', function (e) {
            var _this = this;
            var uniqueID = $(_this).data('path');
            if (textFieldHasChanged(_this)) {
                if (isTagCustomizer) {
                    var currentText = configuratorControlselElement.find('.add-personalization-option-text').val();
                    if (currentText.length === 0 || currentText === '') {
                        configuratorControlselElement.removeAttr('data-current-text');
                    }
                }
                handleCharacterLimit(_this, e);
                delay (function () {
                    if (validateMessageTextField(_this)) {
                        clearErrorMessage();
                        if (isTagCustomizer) {
                            configuratorControlselElement.attr('data-current-text', currentText);
                        }
                        if (validateCurrentTab()) {
                            clearTabAwayError();
                        }
                        submitTextFieldUpdate(_this);
                    } else {
                        if (isTagCustomizer) {
                            clearErrorMessage();
                            if (isTagCustomizer) {
                                configuratorControlselElement.attr('data-current-text', currentText);
                            }
                            if (validateCurrentTab()) {
                                clearTabAwayError();
                            }
                            submitTextFieldUpdate(_this);
                        }
                    }
                }, window.CustomizerResources.PREF_TEXT_FIELD_DELAY, uniqueID);
            }
        });

        // Bind event handler to configurator quantity change
        $('.configurator-controls').on('change', '#Quantity', function () {
            var json = buildRequestJSONFromElement(this);
            updateCustomizedProduct(window.CustomizerResources.URL_UPDATE_JSON, json);
        });

        //Bind click event to clearHeadWear link, if rendered
        $('.configurator-controls').on('click', '#clearHeadWear, #clearTag', function (e) {
            e.preventDefault();
            clearCustomizedProduct(window.CustomizerResources.URL_CLEAR_BALL, page.refresh());
        });

        //Bind click event to attemptRestoreGolfBall link, if rendered
        $('.configurator-controls').on('click', '#attemptRestoreGolfBall', function (e) {
            e.preventDefault();
            // remove the restore param first so we don't add multiple
            window.location.search = util.removeParamFromURL(window.location.search, 'restore') + '&restore=true';
        });

        // [#ASA-151] - My Pro V1 - completion of Ball Model Switch functionality
        // Handles events on moving back and forward by browser history
        // Here happens updating images, logo, product resources like URLs etc.
        window.onpopstate = history.onpushstate = function (e) {
            var pid = e.state.productID,
                el = $('[data-pid="' + pid + '"]').get(0),
                json = buildRequestJSONFromElement(el);
            validateCurrentTab(el);

            updateCustomizedProduct(window.CustomizerResources.URL_REFRESH_CONTROLS, json, function (response) {
                $('#configurator-refresh').html(response);
                var newimages = getNewImageryDataFromHTMLResponse(response);
                var gotoSlide = $(el).data('face') || $(el).closest('.configurator-option-group').data('face');
                imagery.replaceCaroImages(newimages, gotoSlide);
                if (el.name === 'headWearModelSelect' || el.name === 'tagModelSelect') {
                    imagery.replaceLogo(el);
                    updateAllResourceProductURLs(el);
                    $('.color-value').text($("input[name='headWearModelSelect'].configurator-option-select:checked, input[name='tagModelSelect'].configurator-option-select:checked").data('label'));
                    $('#customizer-productID').text($("input[name='headWearModelSelect'].configurator-option-select:checked, input[name='tagModelSelect'].configurator-option-select:checked").attr('data-setvalue').trim());
                }
                launchConfigurationAlerts(response);
                updatePriceAndPromotion(response);
                stickyBall();
            });
        };

        $(document).on('click', '.p1-header .customize-button', function (e) {
            if (!$('#club-pdp-main').hasClass('.club-pdp-main')) {
                e.preventDefault();

                var position = $('.product-core-info').length > 0 ? $('.product-core-info').offset().top : 0;
                util.scrollBrowser(position);
            }
        });

        imageSliders.setupRecCarousel();
        anchorScroll.initAnchorScroll();
        p1Content.init();
        stickyBall();
        validateCurrentTab();
    }
};

/******************* Private Functions ********************/

/**
 * @description Send the AJAX request to update the golf ball.  Response will either be in JSON or HTML, depending on which url was called.
 * @param href {String} the URL to submit the request to
 * @param json {Object} the data object containing any request parameters
 * @param tab {String} (Optional) override which tab to force open on refresh of controls panel. ex: 'messages'
 * @param callback {Function} (Optional) a callback function to execute on AJAX response
 */
var updateCustomizedProduct = function (href, json, tab, callback) {
    if (!json) {
        return false;
    }
    if (typeof tab === 'function' && !callback) { //allow callback to be the 3rd param if no tab is passed
        callback = tab;
        tab = null;
    }
    if (!!tab && typeof json === 'object') {
        json.tab = tab;
    }
    href = href || window.CustomizerResources.URL_UPDATE_JSON;
    stickyBall();
    // make the AJAX request
    return TPromise.resolve($.ajax({
        type: 'POST',
        url: href,
        data: json,
        success: function (response) {
            // after AJAX response...
            if (this.dataTypes.indexOf('json') > -1) {
                if (response.success) {
                    updateTabsComplete(response.tabsComplete);
                    if (response.isValid) {
                        if (isTagCustomizer) {
                            if ($('#messages-tab').hasClass('selected')) {
                                if (validateMessageTextField(configuratorControlselElement.find('.add-personalization-option-text'))) {
                                    enableAddToCart();
                                } else {
                                    disableAddToCart();
                                }
                            } else {
                                enableAddToCart();
                            }
                        } else {
                            enableAddToCart();
                        }
                    } else {
                        disableAddToCart();
                    }
                    // execute the callback function, if defined
                    if (typeof callback === 'function') {
                        callback(response);
                    }
                } else if (response.error) {
                    showErrorMessage(response.error);
                    if (isTagCustomizer) {
                        disableAddToCart();
                    }
                } else {
                    page.refresh();
                }
            } else { //assume HTML response
                var $html = $('<div></div>').html(response);
                var $isValid = $html.find('input#HeadWearIsValid, input#TagIsValid');
                var $updateError = $html.find('input#updateHeadWearError, input#updateTagError');
                //HTML response will always update Tabs Complete
                if ($isValid.val() === 'true') {
                    enableAddToCart();
                } else {
                    disableAddToCart();
                }
                if ($updateError.length) {
                    showErrorMessage($updateError.val());
                    if (isTagCustomizer) {
                        disableAddToCart();
                    }
                } else {
                    // execute the callback function, if defined
                    if (typeof callback === 'function') {
                        callback(response);
                    }
                }
            }
        },
        fail: function () {
            page.refresh();
        }
    }));
};

/**
 * @description Send the AJAX request to clear the golf ball.
 * @param href {String} the URL to submit the request to
 * @param tab {String} (Optional) override which tab to force open on refresh of controls panel. ex: 'messages'
 * @param callback {Function} (Optional) a callback function to execute on AJAX response
 */
var clearCustomizedProduct = function (href, tab, callback) {
    if (typeof tab === 'function' && !callback) { //allow callback to be the 3rd param if no tab is passed
        callback = tab;
        tab = null;
    }
    // make the AJAX request
    return TPromise.resolve($.ajax({
        type: 'POST',
        url: href,
        success: function (response) {
            // execute the callback function, if defined
            if (typeof callback === 'function') {
                callback(response);
            }
        },
        fail: function () {
            page.refresh();
        }
    }));
};


/**
 * @description Updates the golf ball object, then refreshes the configuration panel with the returned HTML
 * @param el {Element} the html element that changed or was clicked
 * @param updateImages {Boolean} (Optional) replace the main product image with new src's from Scene7
 * @param callback {Function} (Optional) a callback function to execute on AJAX response
 * @return {Boolean} success
 */
var updateAndRefreshControls = function (el, updateImages, callback) {

    //check validity based on classes or element state
    if (!isValidOption(el)) {
        return false;
    }

    //just in case, revalidate tab. this will clear any existing error data so it is not retained after the AJAX refresh
    validateCurrentTab(el);

    //extract the data-path and value from this element, or from its ancestors/children if grouped
    var jsondata = buildRequestJSONFromElement(el);

    //if a ball model was selected, update the window.CustomizerResources.URL_REFRESH_CONTROLS with the new product id BEFORE we submit to it
    if (el.name === 'headWearModelSelect' || el.name === 'tagModelSelect') {
        updateAllResourceProductURLs(el);
        $('.color-value').text($("input[name='headWearModelSelect'].configurator-option-select:checked, input[name='tagModelSelect'].configurator-option-select:checked").data('label'));
        $('#customizer-productID').text($("input[name='headWearModelSelect'].configurator-option-select:checked, input[name='tagModelSelect'].configurator-option-select:checked").attr('data-setvalue').trim());
    }

    // execute the AJAX call, expecting HTML response
    $('#product-content').addClass('loading');

    updateCustomizedProduct(window.CustomizerResources.URL_REFRESH_CONTROLS, jsondata, function (response) {
        // after successful response...
        $('#configurator-refresh').html(response);
        if (isTagCustomizer) {
            var currentText = configuratorControlselElement.attr('data-current-text') ? configuratorControlselElement.attr('data-current-text') : configuratorControlselElement.find('.add-personalization-option-text').val();
            if ($('#messages-tab').hasClass('selected')) {
                if (typeof currentText !== 'undefined' && currentText !== false && currentText != '') {
                    configuratorControlselElement.find('.add-personalization-option-text').val(currentText).blur();
                    handleCharacterLimit($('.add-personalization-option-text'));
                } else {
                    disableAddToCart();
                }
                $('.tag-configurator-message-summary-line').show();
            }
        }

        if (updateImages) {
            var newimages = getNewImageryDataFromHTMLResponse(response);
            var gotoSlide = $(el).data('face') || $(el).closest('.configurator-option-group').data('face');
            if (isTagCustomizer) {
                if ($('#tagModel-tab').hasClass('selected')) {
                    gotoSlide = '_01';
                }
            }
            imagery.replaceCaroImages(newimages, gotoSlide);
        }

        // If ball model was selected, update the page logo, page URL, and PID for the addToCart button
        if (el.name === 'headWearModelSelect' || el.name === 'tagModelSelect') {
            imagery.replaceLogo(el);
            updatePageURL(el);
            updateAddToCartButton(el);
            $('.color-value').text($("input[name='headWearModelSelect'].configurator-option-select:checked, input[name='tagModelSelect'].configurator-option-select:checked").data('label'));
            $('#customizer-productID').text($("input[name='headWearModelSelect'].configurator-option-select:checked, input[name='tagModelSelect'].configurator-option-select:checked").attr('data-setvalue').trim());
        }

        launchConfigurationAlerts(response);
		updatePriceAndPromotion(response);

        validateCurrentTab(el);

        // execute the callback function, if defined
        if (typeof callback === 'function') {
            callback(response);
        }

        $('#product-content').removeClass('loading');
    });
};


/**
 * Check if the given selection is valid, based on "disabled" states and classes of element and relative elements
 * @param el {Element} The element to check for validity
 * @returns success boolean
 */
var isValidOption = function (el) {
    // If the option is disabled, show error message and return
    return !($(el).hasClass('disabled') || $(el).is(':disabled') || $(el).parents('.disabled').length);

};

var stickyBall = function () {
	var desktopSticky = function () {
        var $anchor = $('#pdpMain .product-core-info'),
        $controls = $('#product-content'),
        $ball = $('.product-image-container'),
        $ballHeight = $('.product-image-container').outerHeight(true),
		$ballOuter = $ball.closest('.product-image-container-outer'),
		$productDetailsHeight = $('.product-col-2.product-detail').outerHeight(true),
        vp = viewport(),
        $window = $(window),
		st,
		ot,
		ob,
		bh;
        if ($anchor.length && $window.length && $ball.length) {
            st = $window.scrollTop();
            ot = $anchor.offset().top;
            ob = ot + $anchor.height(); //offest.top of bottom of pdpMain
			if ($productDetailsHeight > $ballHeight) {
				if ($('.product-image-container-height').length === 0) {
					$ball.parent().append('<span class="product-image-container-height"/>');
				}
				if ($ball.closest('#QuickViewDialog').length > 0) {
					$ball.css({'max-width': $ballOuter.outerWidth(true)});
				}
				if (st > ot) {
					$ball.addClass('sticky');
					bh = $ball.outerHeight() + 15;
					if (st < ob - bh) {
						$ball.css('top', '0px');
						$ball.removeClass('scroll-parked');
					} else {
						$ball.css('top', 'auto');
						$ball.addClass('scroll-parked');
                        $ball.css('top', ob - st - $ballHeight);
					}
					$('.product-image-container-height').css({'min-height': $ballHeight}).addClass('show');
				} else {
					$ball.removeClass('sticky scroll-parked');
					$ball.css('top', '');
					$('.product-image-container-height').css({'min-height': $ballHeight}).removeClass('show');
				}
			} else {
				$('.product-image-container-height').remove();
				$ball.removeClass('sticky scroll-parked');
				$ball.css({'top': '', 'max-width': ''});
			}
        }
		if (vp.width <= 767 && vp.height > vp.width) {
			var promoHeight = 0;
			if ($('.promotion-callout.mobile').length) {
				promoHeight = $('.promotion-callout.mobile').outerHeight(true);
			}
			$controls.css({'margin-top': $('.primary-image').outerHeight(true) + headerHeight + promoHeight + 40});
		} else {
			$controls.css({'margin-top': 'auto'});
		}
	}

	$(window).on('scroll', function () {
		var $slider = $('.product-primary-image.configurator-main-image');

		if ($slider.length > 0) {
			var sliderPos = $slider.offset().top;
			var scrollPos = $(window).scrollTop();

			if ($('nav.primary').length > 0) {
				if (util.getViewport() > 768) {
					desktopSticky();
				}

				if (util.getViewport() <= 768 && scrollPos >= sliderPos) {
					$slider.addClass('sticky-mobile');
				} else {
					$slider.removeClass('sticky-mobile');
				}
			}
		}
	})
};

/**
 * @description Build a data object of query string params to append to our AJAX request url by extracting
 *     data attributes from the given element or its relatives
 * @param el {Element} the html element to extract a data-path and value from
 * @return {Boolean} success
 */
var buildRequestJSONFromElement = function (el) {

    // extract the data-path value from this element, or from an ancestor if this element is grouped
    var paths = findDataPathsForElement(el);

    // extract the "data-setvalue" or "value" attribute val from this element or a selected child element, if grouped
    var newval = findDataValueForElement(el);

    // with data-path and data-setvalue definitions, we can build the JSON object to make the update request
    // this will be merged into the existing GolfBall config object on the backend
    var configUpdateJSON = generateUpdateJSON(paths, newval);

    // a query string param JSON object containing the created JSON, stringified (and encoding for a query string?)
    var jsondata = {newjson: JSON.stringify(configUpdateJSON)};

    // retrieve the submission URL and add 'tab' parameter to JSON obj
    var activeTab = $('.config-master-tabs > li.selected').attr('id').split('-tab')[0];
    if (!!activeTab) {
        jsondata.tab = activeTab;
    }

    // Return the data object to be used in the actual AJAX call "data" param
    return jsondata;
};


/**
 * @description Find the applicable data-path(s) for this element, either on the element itself, or on an ancestor.
 * @param el {Element} the html element to extract a data-path value from (or get from an ancestor, if grouped)
 * @return {String}
 */
var findDataPathsForElement = function (el) {
    var paths = $(el).data('path') || '';
    // if this is part of a group of options, get the group parent
    var $ancestor = $(el).closest('.configurator-option-group');
    if ($ancestor.data('path')) {
        if (paths !== '') {
            paths += ',';
        }
        paths += $ancestor.data('path');
    }
    return paths;
};


/**
 * @description Get the value of the element, or the element group by searching through nested elements
 * @param el {Element} the form element that contains the value
 * @returns {String} the found value
 */
var findDataValueForElement = function (el) {
    // setting a data-value attribute trumps any other method
    var rtn;
    if ($(el).data('setvalue')) {
        rtn = $(el).data('setvalue');
        return rtn;
    }
    // return the jQuery .val() from element with a "value" attribute
    if (!!$(el).val()) {
        rtn = $(el).val();
        if ($(el).is('input[type="text"]')) {
            // This conversion to uppercase must only be done on submit of value, and only performed on the copied var "rtn",
            // because changing the actual .val() of the text field would result in resetting the user's cursor position.
            rtn = rtn.toUpperCase();
        }
        return rtn;
    }
    // look for any nested element with the class "selected", then try to get that element's value
    if ($(el).find('.selected').length) {
        rtn = findDataValueForElement($(el).find('.selected')[0]);
        return rtn;
    }
    // look for any nested option/radio element that is currently selected, then try to get that element's value
    if ($(el).find(':checked').length) {
        rtn = findDataValueForElement($(el).find(':checked')[0]);
        return rtn;
    }
    // fallback: return the text value of the current container, ex: "hello" for <p>hello</p>
    return $(el).text();
};

/**
 * @description Build the JSON object that will be merged into the existing Golf Ball config object on the backend.
 * @param paths {String} The string representation of the JSON path to the object property we wish to update/create. Can contain "=value".
 *         ex1: 'shapes.square.height'
 *         ex2: 'shapes.square.height="32ft"'
 * @param newval The new value to set in the last object property found in the path. Can be a String, Number, or even null, I guess.
 *         ex1: '32ft'
 *         ex2: 12
 * @returns {Object} the JSON update object
 */
var generateUpdateJSON = function (paths, newval) {
    var pathJSON = {};
    var propertyPaths = typeof paths === 'string' ? paths : null;
    var propertyValue = newval.toString();
    if (!!propertyPaths) {
        //split at comma to determine if multiple values are being set
        paths = propertyPaths.split(',');
        for (var i = 0; i < paths.length; i++) {
            var propertyPath = paths[i].trim();
            var thisPropertyValue = propertyValue;
            if (propertyPath.indexOf('=') > -1) {
                //check if the path contains '=', if so, split out path and property value. this overrides the passed in param for value
                thisPropertyValue = propertyPath.split('=')[1].trim();
                propertyPath = propertyPath.split('=')[0].trim();
            }
            // break this string into an array of property names
            var pathArr = propertyPath.split('.');

            if (pathArr.length > 0) {
                createNestedObject(pathJSON, pathArr, thisPropertyValue);
            }
        }
    }
    return pathJSON;
};

/**
 * @description Compare the current value of an input text with its previous value
 * @param el {Element} the element to check
 * @return {Boolean} true if input value has changed, false if not
 */
var textFieldHasChanged = function (el) {
    //If value has changed...
    if ($(el).data('oldvalue').toString() !== $(el).val().toString()) {
        // Updated stored value in data-oldvalue attribute
        $(el).data('oldvalue', $(el).val());
        return true;
    }
    return false;
};

/**
 * @description Update Golf Ball with the text field's new value
 * @param el {Element} the element containing the new value
 */
var submitTextFieldUpdate = function (el) {
    var json = buildRequestJSONFromElement(el);
    var gotoSlide = $(el).data('face');
    if (isTagCustomizer) {
        gotoSlide = configuratorControlselElement.find('.configurator-option.selected .configurator-option-select').attr('data-active-slide');
    }
    // execute AJAX call, expecting JSON response (because we not don't want a refresh)
    updateCustomizedProduct(window.CustomizerResources.URL_UPDATE_JSON, json, function (response) {
        // we are not refreshing, but let's at least update the summary with the right text
        updateSummaryMsgText(response.config);
        imagery.replaceCaroImages(response.images, gotoSlide);
    });
};

/**
 * Extract the Golf Ball's new Scene7 imagery from an HTML response, parsing out hidden input fields.
 * @param response {String} the returned html from an AJAX call to be parsed for new Scene7 image urls
 */
var getNewImageryDataFromHTMLResponse = function (html) {
    //extract the new image urls from the html provided
    var $source = $(html);
    var images = [];
    var $hiddenInputs = $source.find('input#scene7url[type="hidden"]');
    $hiddenInputs.each(function () {
        images.push($(this).val());
        //if we need to treat this as an associative array, use the following instead:
        //var key = $(this).attr('name');
        //var val = $(this).val();
        //images[key] = val;
    });
    return images;
};

/**
 * Update the Message 1 and Message 2 lines' text in the bottom summary section
 * @param config {Object} the current configuration data from which to pull the new text values
 */
var updateSummaryMsgText = function (config) {
    // I know these are throwing JS Lint errors, but the naming conventions here are defined in the backend ds file
    $('#summary-msg-N-line1').text(String.format(window.CustomizerResources.TEXT_SUMMARY_LINE1, config.messages.message_1.line1.msg));
};

/**
 * @description Mark tabs with a green checkmark by adding the class "complete".
 */
var updateTabsComplete = function (list) {
    if (typeof list !== 'object') {
        return false;
    }
    var $allTabs = $('.configurator-controls').find('.config-tab');
    //remove 'complete' class from all tabs
    $allTabs.removeClass('complete');
    //then add to only those that are officially complete, per server-side validation
    $allTabs.each(function () {
        for (var i = 0; i < list.length; i++) {
            if ($(this).is('#' + list[i] + '-tab')) {
                $(this).addClass('complete');
            }
        }
    });
    return true;
};

/**
 * @description Pop up the Invalid Play Number warning, or any other custom configuration Alert coming from the pdict.
 */
var launchConfigurationAlerts = function (response) {
    var $content = $(response).find('#configuration-alert-to-dialog');
    if ($content.length > 0) {
        // Open alerts into dialog box(es) -
        // an option for displaying backend-delivered alerts (Close buttons should be added to content asset html)
        dialog.open({
            html: $content.html(),
            options: {
                title: $content.attr('title'),
                dialogClass: 'configuration-alert-dialog',
                open: function () {
                    $('.configuration-alert-dialog').on('click', 'button.close-dialog', function () {
                        $('.ui-dialog-content').dialog('close');
                    });
                }
            }
        });
        // Show alerts as red error text -
        // another option to display the error (but the Close button should be removed from the content asset html)
        //showErrorMessage($content.html());
    }
};


/**
 * @description Validate the message text field against existing pattern attribute
 * @return Boolean valid or not
 */
var validateCurrentTab = function (el, skipEmptyValidation) {
    var valid = true;
    if ($currentTabContent.is('#headWearModel-content')) {
        valid = true;
    }
    if ($currentTabContent.is('#messages-content')) {
        $('.configurator-option.selected input.msgtxt').each(function () {
            if (!validateMessageTextField(this)) {
                valid = false;
                return false; //this just breaks the loop
            }
        });
        if (!skipEmptyValidation && !validateAnyMessageTextExists(false, el)) {
            if (isTagCustomizer) {
                valid = true;
            } else {
                valid = false;
            }
        }
    }
    if (valid) {
        clearErrorMessage();
        clearTabAwayError();
    }
    return valid;
};

/**
 * @description Validate the message text field against existing pattern attribute
 * @param el {Element} the text element to validate
 * @return Boolean valid or not
 */
var validateMessageTextField = function (el) {
    var val = $(el).val();
    var isPersonalizeTabActive = $('#messages-tab.selected').length > 0;
    if (isTagCustomizer && isPersonalizeTabActive) {
        $('.add-personalization-option-error-msg').addClass('visually-hidden');
        $('.add-personalization-option-label').removeClass('error');
        $('.add-personalization-option-text').removeClass('error');
        if (val === null || val === '') {
            disableAddToCart();
            return false;
        }
    }
    if ($(el).data('missingerror') && (val === null || val === '')) {
        showErrorMessage($(el).data('missingerror'));
        setTabAwayError($(el).data('missingerror'));
        disableAddToCart();
        return false;
    }
    if (new RegExp($(el).attr('pattern')).test(val) === false) {
        showErrorMessage($(el).data('regexerror') || 'The text you entered was invalid.');
        setTabAwayError($(el).data('regexerror') || 'The text you entered was invalid.');
        disableAddToCart();
        return false;
    }
    return true;
};

/**
 * @description Validate that not all message text fields are empty
 * @param showError {Boolean} Optional - if true, will throw an error message if invalid, otherwise, only sets TabAway error message
 * @param el {Element} Optional - the recently clicked element, in case a radio button was clicked, it wouldn't register as "clicked" yet
 * @return Boolean valid or not
 */
var validateAnyMessageTextExists = function (showError, el) {
    var $msg1texts;
    var msg1invalid = false;
    var err;

    $msg1texts = $('input.message-single-1');
    msg1invalid = true;
    $msg1texts.each(function () {
        msg1invalid = msg1invalid && $(this).val() === '';
    });
    if (msg1invalid) {
        err = 'You must enter text on at least one line, or select "None".';
        setTabAwayError(err);
        disableAddToCart();
        if (showError) {
            showErrorMessage(err);
        }
        return false;
    }

    return true;
};

/**
 * @description Disabled the Add To Cart button, as this tab is now invalid
 */
var disableAddToCart = function () {
    var isTagCustomizer = $('.pdp-main.pdp-tag-customizer').length > 0;
    if (isTagCustomizer) {
        $('.add-to-cart').addClass('disabled');
    } else {
        $('.add-to-cart').attr('disabled', 'disabled');
    }
    $('#wishlist-link').addClass('hide');
};

/**
 * @description Enable the Add To Cart button, as this tab should now be valid
 */
var enableAddToCart = function () {
    var isTagCustomizer = $('.pdp-main.pdp-tag-customizer').length > 0;
    if (isTagCustomizer) {
        $('.add-to-cart').removeClass('disabled');
    } else {
        $('.add-to-cart').removeAttr('disabled');
    }
    $('#wishlist-link').removeClass('hide');
};

/**
 * @description Show an error message at the top of the tab section that is currently open
 * @param error {String} The error message to show
 * @returns {jQuery} the error message div
 */
var showErrorMessage = function (error) {
    return $('.configurator-controls').find('.config-tab-content.open .error-msg').html('<p>' + error + '</p>');
};

/**
 * @description Show an error message at the top of the tab section that is currently open
 * @returns {jQuery} the error message div
 */
var clearErrorMessage = function () {
    return $('.configurator-controls').find('.config-tab-content.open .error-msg').html('');
};

/**
 * @description Set an error message to show if a different tab is clicked.
 * @param error {String} The error message to show
 */
var setTabAwayError = function (error) {
    if (error !== null) {
        $currentTabContent.addClass('invalid');
        $currentTab.addClass('has-error');
        return $currentTabContent.data('validationerror', error);
    }
};

/**
 * @description Set an error message to show if a different tab is clicked.
 * @param error {String} The error message to show
 */
var clearTabAwayError = function () {
    $currentTabContent.removeClass('invalid');
    $currentTab.removeClass('has-error');
    return $currentTabContent.data('validationerror', null);
};

/**
 * @description Controls Tab-switching UI
 * @param el {Element} the tab element clicked
 * @param callback {Function} a function to execute after successful tab switch
 */
var masterTabSwitch = function (el, callback) {
    //execute validation checks and set error classes and messaging accordingly (if nec)
    // Skip validation for bag customizer pdp
    validateCurrentTab(null, $('.tag-customizer').length > 0);

    if ($('#tag-error').length) {
        $('.message-single-1').prop('disabled', true);
        $('.configurator-text-font-select').prop('disabled', true);

        disableAddToCart();
    }

    if ($currentTabContent.hasClass('invalid')) {
        showErrorMessage($currentTabContent.data('validationerror') || 'Please ensure your entry below is valid.');
        return false;
    }
    if ($(el).hasClass('disabled')) {
        showErrorMessage($(el).data('error') || $(el).data('validationerror') || 'Invalid choice');
        return false;
    }
    var $openedTabContent = switchAriaTab(el);
    if ($openedTabContent.length) {
        $currentTabContent = $openedTabContent;
        $currentTab = $(el);
        if (!isTagCustomizer) {
            imagery.gotoSlide($(el).data('face'));
        } else {
            if ($('#messages-tab').hasClass('selected')) {
                var currentFont = configuratorControlselElement.attr('data-current-font');
                var currentText = configuratorControlselElement.attr('data-current-text') ? configuratorControlselElement.attr('data-current-text') : configuratorControlselElement.find('.add-personalization-option-text').val();
                var savedFont = false;
                var savedText = false;
                if (typeof currentFont !== 'undefined' && currentFont !== false) {
                    savedFont = true;
                }
                if (typeof currentText !== 'undefined' && currentText !== false && currentText != '') {
                    savedText = true;
                }
                if (savedFont === false && savedText === false) {
                    var activeSlide = tagCustomizer.find('.configurator-option.selected .configurator-option-select').data('active-slide');
                    imagery.gotoSlide(activeSlide);
                }
            }
        }
        validateCurrentTab(); //why not
        if (typeof callback === 'function') {
            callback(el);
        }
        return true;
    }
    return false;
};

/******************* Utility Functions **********************/


/**
 * @description Controls Tab-switching UI purely through ARIA attributes
 * @param el {Element} the tab element clicked
 * @return {Element} the newly opened tab content div
 */
var switchAriaTab = function (el) {
    var tabPanelId = $(el).attr('aria-controls'); //find out what tab panel this tab controls
    var $selectedTabContent = $('#' + tabPanelId);
    if ($selectedTabContent.length) {
        $(el).addClass('selected').attr('aria-selected', 'true').siblings('[role="tab"]').removeClass('selected').attr('aria-selected', 'false');
        $selectedTabContent.addClass('open').attr('aria-hidden', 'false').siblings('[role="tabpanel"]').removeClass('open').attr('aria-hidden', 'true');
        var isTagCustomizer = $('.pdp-main.pdp-tag-customizer').length > 0;
        var isColorTabActive = $('#tagModel-tab.selected').length > 0;
        if (isTagCustomizer && isColorTabActive) {
            enableAddToCart();
        }
    }
    return $selectedTabContent;
};

/**
 * @function createNestedObject(base, path, value)
 * @description create a hierarchy of nested objects mirroring the path structure in the given string
 * @example createNestedObject( geometry, ["shapes", "circle"] ); //Now geometry.shapes.circle is an empty object, ready to be used.
 * @example createNestedObject( geometry, ["shapes", "rectangle", "width"], 300 ); //Now we have: geometry.shapes.rectangle.width === 300
 * @param base {Object} the object on which to create the hierarchy
 * @param path {Array} an array of strings which contains the object path to be created, ex: ['messages','selectedMessageType']
 * @param value (optional): if given, will be the last object in the hierarchy. can be string, number, or probably object or null
 * @return the last object in the hierarchy
 */
var createNestedObject = function (base, path, value) {
    base = typeof base === 'object' ? base : {};
    if (path.length > 0) {
        // If a value is given, remove the last property in the path and keep it for later:
        var lastProp = arguments.length === 3 ? path.pop() : false;
        // Walk the hierarchy, creating new objects where needed.
        // If the lastProp was removed, then the last object is not set yet:
        for (var i = 0; i < path.length; i++) {
            var prop = path[i].trim();
            base = base[prop] = base[prop] || {};
        }
        // If a value was given, set it to the last name:
        if (lastProp) {
            base = base[lastProp] = value;
        }
        // Return the last object (or value) in the hierarchy
        return base;
    }
};

/**
 * Capitalize, and Capture keystrokes and alert number of characters remaining in max-length text field
 * @param el {jQuery} the jQuery object that was acted upon
 * @param e {Event} the jQuery event triggered
 */
var handleCharacterLimit = function (el) {
    // Character Limit
    var text = $(el).val(),
        charsLimit = $(el).attr('maxlength'),
        charsUsed = text.length,
        charsRemain = charsLimit - charsUsed;
    if (charsRemain < 0) {
        $(el).val(text.slice(0, charsRemain));
        charsRemain = 0;
    }
    $(el).next('div.char-count').find('.char-remain-count').html(charsRemain);
};

/**
 * @description Execute a function after the user has stopped typing for a specified amount of time
 * @param {Function} callback function to execute after a delay
 * @param {Number} delay in milliseconds
 */
var delay = (function () {
    var wait = 0;
    var lastUID;
    var functionQueue = [];
    var oneTimeFunction;
    return function (callback, ms, thisUID) {
        clearTimeout (wait);
        if (typeof callback !== 'function') {
            return;
        }
        if (typeof lastUID === 'undefined') {
            lastUID = thisUID;
        }
        if (lastUID !== thisUID) {
            lastUID = thisUID;
            functionQueue.push(callback);
        } else {
            oneTimeFunction = callback;
        }
        wait = setTimeout(function () {
            //execute all functions in the queue
            while (functionQueue.length > 0) {
                functionQueue[0]();
                functionQueue.shift();
            }
            if (typeof oneTimeFunction === 'function') {
                oneTimeFunction();
            }
        }, ms);
    };
})();

/**
 * @description Utility function to scroll to a certain element in the window
 * @param el {Element} the element to scroll to
 */
var scrollTo = function (el) {
    $(window).scrollTop(el.position().top);
};

function viewport() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    return {width: e[a + 'Width'] , height: e[a + 'Height']};
}

/**
 * @description Update the addressbar PDP URL
 * @param el {Element} the element containing the new PDP URL value
 */
var updatePageURL = function (el) {
    var newURL = $(el).data('pdp-url');
    var productID = $(el).data('pid');

    if (el) {
        window.history.pushState(
            {productID: productID},
            $(document).find('title').text(),
            newURL
        );
    }
};

// Update all resource URLS containing a PID, except for the Clear Ball (Start Over) URL
var updateAllResourceProductURLs = function (el) {
    var newPID = $(el).data('pid');
    window.Urls.addProduct = window.CustomizerResources.URL_ADD_PRODUCT = util.appendParamToURL(window.CustomizerResources.URL_ADD_PRODUCT, 'pid', newPID, true);
    window.CustomizerResources.URL_REFRESH_CONTROLS = util.appendParamToURL(window.CustomizerResources.URL_REFRESH_CONTROLS, 'pid', newPID, true);
    window.CustomizerResources.URL_UPDATE_JSON = util.appendParamToURL(window.CustomizerResources.URL_UPDATE_JSON, 'pid', newPID, true);
};

/**
 * @description Update the addToCart to submit a new Product ID
 * @param el {Element} the element containing the new PID
 */
var updateAddToCartButton = function (el) {
    var newPID = $(el).data('pid');
    var $selectQty = $('.configurator-addtocart').find('select#Quantity'),
        $wishLink = $('.configurator-addtocart').find('#wishlist-link'),
        $hiddenInput = $('.configurator-addtocart').find('input#pid');
    var newSelectURL = util.appendParamToURL($selectQty.data('href'), 'pid', newPID, true),
        newWishURL = $wishLink.length > 0 ? util.appendParamToURL($wishLink.attr('href'), 'pid', newPID, true) : '';

    $selectQty.attr('data-href', newSelectURL);
    $wishLink.attr('href', newWishURL);
    $hiddenInput.val(newPID);
};

var updatePriceAndPromotion = function (response) {
	$('div.product-price').each(function () {
		$(this).html($(response).find('div.product-price').html());
	});
	$('div.promotion').each(function () {
		$(this).html($(response).find('div.promotion').html());
	});
};

module.exports = controls;
