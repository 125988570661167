'use strict';

var page = require('../../page'),
util = require('../../util');

var myTitleistDesignsKey = 'myTitleistDesignsKey';

/**
Saves custom Titleis designs to local storage and handles expiration.
@param {Object} configObject - Configuration object containing design details.
@param {number} maxDays - Number of days the designs should be kept in local storage.
@returns {void}
@description This function saves custom Titleist designs to local storage, and checks for expiration of existing designs.
If the designs have expired, they are removed from storage. If a design is new, it is added to the storage. If a design
is being updated, the old design is removed and the new design is added.
*/
function saveMyTitleistDesignsToLocalStorage(configObject, maxDays) {
    var myTitleisDesignsMaxDays = parseInt(maxDays, 10);
    var myTitleisDesigns = JSON.parse(window.localStorage.getItem(myTitleistDesignsKey)) || {lastUpdated: null, designs: {}};

    // Check if myTitleistDesigns is expired
    var currentDate = new Date();
    var lastUpdated = new Date(myTitleisDesigns.lastUpdated);
    var expirationDate = new Date(lastUpdated.getTime() + (myTitleisDesignsMaxDays * 24 * 60 * 60 * 1000));

    if (currentDate > expirationDate) {
        myTitleisDesigns.designs = {};
        myTitleisDesigns.lastUpdated = currentDate.toISOString();
    } else {
        myTitleisDesigns.lastUpdated = currentDate.toISOString();
    }

    var payload = configObject && configObject.length > 0 ? configObject[0].payload : null;
    var masterId = payload && payload.masterid ? payload.masterid : null;
    var recipeId = payload && payload.recipe ? payload.recipe : null;
    var previewUrl = configObject && configObject.length > 0 ? configObject[0].previewUrl : null;

    if (!payload || !masterId || !recipeId || !previewUrl) {
        return;
    }

	var skuDesigns = myTitleisDesigns.designs[masterId] || [];
	var newDesignObj = {recipeId: recipeId, masterId: masterId, payload: payload, previewUrl: previewUrl};

    var existingDesignIndex = -1;

    for (var i = 0; i < skuDesigns.length; i++) {
        if (skuDesigns[i].recipeId === recipeId) {
            existingDesignIndex = i;
            break;
        }
    }

    if (existingDesignIndex !== -1) {
        skuDesigns.splice(existingDesignIndex, 1);
    }

    skuDesigns.push(newDesignObj);

    myTitleisDesigns.designs[masterId] = skuDesigns;

    window.localStorage.setItem(myTitleistDesignsKey, JSON.stringify(myTitleisDesigns));
}

/**
Renders the FJ designs saved in local storage for a given master ID.
@param {string} masterID - The master ID of the product to render FJ designs for.
@returns {void}
**/
var renderSwatchesFromLocalStorage = function (masterID) {
    var myTitleistDesigns = JSON.parse(window.localStorage.getItem(myTitleistDesignsKey)) || {lastUpdated: null, designs: {}};
    // Find the swatch element container
    var designSwatches = $('.pdp-my-designs');
    var swatchContainer = designSwatches.length ? designSwatches.find('#myDesignSwatches') : null;

    var customProductDesigns = myTitleistDesigns.designs[masterID] || [];

    if (customProductDesigns.length === 0 || !swatchContainer) {
        designSwatches.addClass('visually-hidden');
        return;
    } else {
        designSwatches.removeClass('visually-hidden');
    }

    // Add new swatches based on the saved designs
    swatchContainer.empty();
    customProductDesigns.forEach(function (design) {
        var label = document.createElement('label');
        var img = document.createElement('img');

        var radioID = design.recipeId;

        label.classList.add('swatchanchor', 'radio-button', 'radio-button', 'selectable', 'custom-design-swatch');
        label.setAttribute('for', radioID);
        label.setAttribute('data-master', masterID);
        label.setAttribute('data-recipeId', design.recipeId);
        label.setAttribute('data-payload', JSON.stringify(design.payload));

        img.setAttribute('alt', design.recipeId);
        img.setAttribute('src', design.previewUrl);
        img.setAttribute('width', 57);
        img.setAttribute('height', 57);

        label.appendChild(img);
        swatchContainer.append(label);
    });
};

// Append params to redirect URL for Saved Designs
function appendParamToURL(url, name, value) {
    var separator = url.indexOf('?') !== -1 ? '&' : '?';
    return url + separator + name + '=' + encodeURIComponent(value);
}

$('body').on('customize:saveMyTitleistDesigns', function (event, data) {
    saveMyTitleistDesignsToLocalStorage(data.payload, data.myTitleistSavedDesignMaxDays);
});

/**
* @description Handler to handle the customize event
*/
var customize = function (products, payload, recipeId, initialFinish, initialOrientation, initialShaft, initialGrip, initialAssemblyOption) {
    var $elParentDC = $('#myDesignSwatches');

    var apiKey = $elParentDC.data('customizerKey');
    var recipe = recipeId || null
    var payload = payload || null;
    var products = products || null;
    var initialFinish = initialFinish || null;
    var initialOrientation = initialOrientation || null;
    var initialShaft = initialShaft || null;
    var initialGrip = initialGrip || null;
    var initialAssemblyOption = initialAssemblyOption || null;
    var savedDesignMaxDays = $elParentDC.attr('data-customizer-saved-design-max-days');
    var configuration = payload;
    var leadTimeAddition = $elParentDC.attr('data-lead-time-addition');
    var leadTimeCustom = leadTimeAddition ? Number(leadTimeAddition) : null;
    var cartUrl = $elParentDC.data('customizerCart');
    var currency = $elParentDC.data('customizerCurrency');
    var locale = $elParentDC.data('customizerLocale');

    CustomizerWidget.createWidget({
        apiKey: apiKey,
        products: products,
        initialFinish: initialFinish,
        initialOrientation: initialOrientation,
        initialShaft: initialShaft,
        initialGrip: initialGrip,
        initialAssemblyOption: initialAssemblyOption,
        lineItemId: null,
        recipe: recipe,
        configuration: configuration,
        leadTimeCustom: leadTimeCustom,
        cartUrl: cartUrl,
        currency: currency,
        locale: locale,
        onCancel: function () {
            return true;
        },
        onAddToCart: function (payload, recipe, lineItemId) {

            var cartShowUrlAction = cartUrl;

            var payloadItem = payload && payload.length > 0 && payload[0] && payload[0] ? payload[0] : null;
            var configurationObject = payloadItem && payloadItem.payload ? payloadItem.payload : null;

            var recipeData = recipe ? JSON.stringify(recipe) : null;

            var configuration = configurationObject ? JSON.stringify(configurationObject) : null;
            var dcImageUrl = payloadItem && payloadItem.previewUrl ? payloadItem.previewUrl : null;
            var pid = configurationObject && configurationObject.item ? configurationObject.item : null;
            var quantity = 1;
            var renderState = 'review';

            var productActionUrl = Urls.addWedgeworksToCart;
            if (pid) {
                productActionUrl += '?pid=' + pid;
            }
            productActionUrl += '&Quantity=' + quantity;

            productActionUrl += '&renderState=' + renderState;

            productActionUrl += '&format=' + 'ajax';

            var xhr = new XMLHttpRequest();
            xhr.open('POST', productActionUrl, true);

            var params = new Object();
            if (configuration) {
                params.configuration = configuration;
            }
            if (recipeData) {
                params.recipeData = recipeData;
            }
            if (dcImageUrl) {
                params.dcImageUrl = dcImageUrl;
            }
            var paramsAsString = JSON.stringify(params);
            xhr.send(paramsAsString);

            xhr.onload = function() {
                window.location.href = cartShowUrlAction;
            };

            xhr.onerror = function() {
                window.location.href = cartShowUrlAction;
            };
        },
        onAddToWishlist: function (payload, recipe) {

            var payloadItem = payload && payload.length > 0 && payload[0] && payload[0] ? payload[0] : null;
            var configurationObject = payloadItem && payloadItem.payload ? payloadItem.payload : null;


            var configuration = configurationObject ? JSON.stringify(configurationObject) : null;
            var pid = configurationObject && configurationObject.item ? configurationObject.item : null;

            var addToWishlistUrl = Urls.addToWishlistFromCustomizer;
            addToWishlistUrl += '?pid=' + pid;

            var params = new Object();
            if (configuration) {
                params.configuration = configuration;
            }

            var paramsAsString = JSON.stringify(params);
            addToWishlistUrl += '&encodeddata=' + encodeURIComponent(paramsAsString);

            window.location.href = addToWishlistUrl;
        },
        onSave: function (payload, recipe, lineItemId) {

            $('body').trigger('customize:saveMyTitleistDesigns', {
                payload: payload,
                myTitleistSavedDesignMaxDays: savedDesignMaxDays
            });
            var url = appendParamToURL(Urls.getProductUrl, 'pid', payload[0].payload.masterid);
            window.location.href = url;
        }
    });
};

/**
* @description Handler to handle the manage design click event
*/
var handleManageDesignsClick = function (e) {
    var $this = $(e.target);
    var $myDesigns = $this.closest('.pdp-my-designs');
    var $myDesignsSave = $myDesigns.find('.js-custom-designs-save');
    var $swatches = $myDesigns.find('.custom-design-swatch');
    $('.out-of-stock-message').removeClass('out-of-stock-message-active');
    $myDesigns.addClass('manage-active');

    // On click of each swatches , add class name delete-candidate to the swatch element, only one swatch can be selected at a time
    $swatches.on('click', function (e) {
        if($myDesigns.hasClass('manage-active')) {
            e.stopImmediatePropagation();

            if ($(this).hasClass('delete-candidate')) {
                $(this).removeClass('delete-candidate');
            } else {
                $(this).addClass('delete-candidate');
            }
        }
    });



    $myDesignsSave.on('click', function () {
        // Find swatch element by class name delete-candidate , remove it from DOM and remove it from the local storage myFJDesigns property as well
        var $deleteCandidate = $myDesigns.find('.delete-candidate');

        if ($deleteCandidate.length > 0) {
            var myTitleistDesigns = JSON.parse(localStorage.getItem(myTitleistDesignsKey));
            var masterID = $($deleteCandidate[0]).data('master');

            $deleteCandidate.each(function () {
                var $el = $(this);
                var recipeId = $el.data('recipeid').toString();

                var existingDesignIndex = -1;
                var designs = myTitleistDesigns.designs[masterID];

                for (var i = 0; i < designs.length; i++) {
                    if (designs[i].recipeId === recipeId) {
                        existingDesignIndex = i;
                        break;
                    }
                }

                if (existingDesignIndex !== -1) {
                    myTitleistDesigns.designs[masterID].splice(existingDesignIndex, 1);
                    if (myTitleistDesigns.designs[masterID].length === 0) {
                        delete myTitleistDesigns.designs[masterID];
                    }
                }
            });

            var currentDate = new Date();
            myTitleistDesigns.lastUpdated = currentDate.toISOString();

            window.localStorage.setItem(myTitleistDesignsKey, JSON.stringify(myTitleistDesigns));
            renderSwatchesFromLocalStorage(masterID);
        }

        $myDesigns.removeClass('manage-active');
    });
};

function showAvailabilityErrorMessage(errorTextMsg) {
    // TODO: We show error like alert message for testing only
    // Add changes for showing error based on comps
    window.alert(errorTextMsg);
}

function customizeSavedDesign(e) {
    var $this = $(e.currentTarget);
    var savedPayload = $this.attr('data-payload');
    var payload = {};
    if (savedPayload) {
        payload = JSON.parse(savedPayload.replace(/&quot;/g,'"'));
    }
    var data = {
        payload: payload
    };
    var json = JSON.stringify(data);
    var url = Urls.wedgeworksValidateAvailability;
    $.ajax({
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        url: url,
        data: json,
        success: function (response) {
            if (response && response.success) {
                var isAvailable = response.isAvailable;
                if (isAvailable) {
                    $('.out-of-stock-message').removeClass('out-of-stock-message-active');
                    var recipeId = $this.data('recipeid');
                    var payload = $this.data('payload');
                    var products = payload && payload.item ? [payload.item] : null;
                    customize(products, payload, recipeId);
                } else {
                   $('.out-of-stock-message').addClass('out-of-stock-message-active');
                }
            } else {
                showAvailabilityErrorMessage(Resources.CLUBS_SAVED_DESIGN_OOS_ERROR);
                window.console.error('Error in checking avilability of saved design');
            }
        },
        fail: function () {
            showAvailabilityErrorMessage(Resources.SERVER_CONNECTION_ERROR);
            window.console.error('Something went wrong with request during checking avilability of saved design');
        }
    });
}

function customizeYourOwnDesign() {
    var form = $('.pdpForm');
    var configuration = sanitizeConfig(form);
    var selectedProducts = [];
    if (configuration && configuration['club-setcomp'] && configuration['club-setcomp'].length > 0) {
        var item = configuration.item;
        var selectedSetcopms = configuration['club-setcomp']; // it might be a string or array
        if (typeof selectedSetcopms === 'string' || selectedSetcopms instanceof String) {
            var selectedCompString = selectedSetcopms;
            selectedSetcopms = [selectedCompString];
        }
        var firstPartSelectedClub = item.substring(0, item.lastIndexOf(':'));
        // each formattedSelectedSetcopm should have format "852C:CA-RH:CBW-4610"
        // where firstPartSelectedClub="852C:CA-RH" and selectedSetcopmValue=":CBW-4610" 
        var formattedSelectedSetcopms = selectedSetcopms.map(function (selectedSetcopm) {
            var selectedSetcopmValue = selectedSetcopm.substring(selectedSetcopm.lastIndexOf(':'));
            var formattedSelectedSetcopm = firstPartSelectedClub + selectedSetcopmValue;
            return formattedSelectedSetcopm;
        });
        selectedProducts = formattedSelectedSetcopms;
    }

    // initial shaft
    var initialShaft = configuration && configuration['shaft-option-code'] ? configuration['shaft-option-code'] : null;

    // initial grip
    var gripType = configuration && configuration['grip-type'] ? configuration['grip-type'].split(':').pop() : null;
    var gripSize = configuration && configuration['grip-size'] ? configuration['grip-size'].split(':').pop()  : null;
    var initialGrip = gripType && gripSize ? [gripType, gripSize].join(':') : null;
    var initialAssemblyOption = configuration && configuration['grip-assembly-option'] ? configuration['grip-assembly-option'].split(':').pop()  : null;

    if (selectedProducts.length > 0) {
        customize(selectedProducts, null, null, null, null, initialShaft, initialGrip, initialAssemblyOption);
    } else {
        var masterId = $("input#masterid").val() || null;
        var products = masterId ? [masterId] : null;
        var initialFinish = configuration && configuration['finish'] ? configuration['finish'] : null;
        var initialOrientation = configuration && configuration['orientation'] ? configuration['orientation'] : null;

        customize(products, null, null, initialFinish, initialOrientation, initialShaft, initialGrip, initialAssemblyOption);
    }
}

// Take the serialized array, and convert it into a native object.
function sanitizeConfig(form) {
    var configuration = new Object(),
        serialArray = form.serializeArray(),
        filters = [
            'pid',
            'edit',
            'Quantity',
            'shaft-brand',
            'grip-brand'
        ];

    for (var i = 0; i < serialArray.length; i++) {
        if (filters.indexOf(serialArray[i].name) != -1) {
            continue;
        }
        if (serialArray[i].name === 'club-setcomp') {
            if (serialArray[i].value && serialArray[i].value !== 'null') {
                try {
                    configuration[serialArray[i].name] = JSON.parse(serialArray[i].value);
                } catch (e) {
                    configuration[serialArray[i].name] = serialArray[i].value;
                }
            }
        } else {
            configuration[serialArray[i].name] = serialArray[i].value;
        }
    }

    return configuration;
}

function isCustomizerDCEnabled(enabledCallback, disabledCallback) {
    var url = Urls.wedgeworksCustomizerEnabled;
    $.ajax({
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        url: url,
        success: function (response) {
            if (response && response.isCustomizerDC) {
                if (enabledCallback) {
                    enabledCallback();
                }
            } else {
                if (disabledCallback) {
                    disabledCallback();
                }
            }
        },
        fail: function () {
            showAvailabilityErrorMessage(Resources.SERVER_CONNECTION_ERROR);
            window.console.error('Something went wrong with request during checking if customizer is enabled');
        }
    })
}

function showUnavailableCustomizationModal() {
    var $unavailableCustomizationModal = $('.customizer-unavailable-modal');

    if (!$unavailableCustomizationModal.length) {
        return;
    }

    $unavailableCustomizationModal.dialog({
        height: 'auto',
        modal: true,
        overlay: {
            opacity: 0.5,
            background: 'black'
        },
        dialogClass: 'customizer-unavailable-dialog',
        draggable: false,
        resizable: false,
        width: 'auto',
        close: function () {
            $unavailableCustomizationModal.dialog('destroy');
        }
    });
}

function customizeYourOwnDesignHandler(e) {
    e.preventDefault();
    isCustomizerDCEnabled(customizeYourOwnDesign, showUnavailableCustomizationModal);
}

function customizeSavedDesignHandler(e) {
    e.preventDefault();
    isCustomizerDCEnabled(function() {
        customizeSavedDesign(e);
        },
        showUnavailableCustomizationModal
    );
}

/**
* @function
* @description Binds the click event to a given target for the customize handling
* @param {Element} target The target on which a customize event-handler will be set
*/
module.exports = function (target) {
    renderSwatchesFromLocalStorage($('input[name="masterid"]').val());

    $('body').on('click', '.js-custom-designs-manage', handleManageDesignsClick);
    $('body').on('click', '#design-your-own-swatch', customizeYourOwnDesignHandler);
    $('body').on('click', '.custom-design-swatch', customizeSavedDesignHandler);
};

module.exports.renderSwatchesFromLocalStorage = renderSwatchesFromLocalStorage;
