'use strict';
var carousel = require('./carousel');

/**
 * @private
 * @function
 * @description Addresses timing of carousel init due to Einstein being loaded dynamically after load
 */
function initRecs() {
    var targets = $('*[id^="cq_recomm_slot-"]');
    if (!targets.length) return;
    var config = {attributes: false, childList: true, characterData: false};
    targets.each(function () {
        var observer = new MutationObserver(function () {
			carousel.init();
            observer.disconnect();
        });
        observer.observe($(this)[0], config);
    });

    $('.tabbed-recommendations .config-tab').on('click touchstart', function () {
        var tabTog = $(this).data("toggle");
        //Toggle tab link
        $(this).addClass('nav-tab-active').siblings().removeClass('nav-tab-active');

        //Toggle target tab
        $('div.' + tabTog).addClass('active').siblings().removeClass('active');
        $('.tabs-view').removeClass('active');
        $('a.view-' + tabTog).addClass('active');
		$('.tabbed-recommendations-1x5 .tiles-container').slick('refresh');
	});
}

exports.init = function () {
    initRecs();
};
