'use strict';

/**
 * @private
 * @function
 * @description Binds event to the place holder (.blur)
 */
function initializeEvents() {
	// Intentionally blank, so that it overrides app_storefront_core.
}

exports.init = initializeEvents;
