'use strict';

var addToCart = require('../product/addToCart'),
	imagery = require('./imagery'),
	ordergroove = require('../product/custom/ordergroove'),
	controls = require('./controls'),
    membersOnlyModalAnother = require('../product/membersOnlyModalAnother');

/**
 * @description Initialize event handlers on product detail page
 */
function init() {
    addToCart();
	controls.init();
	imagery.init(removeModalBarrier);
	ordergroove.init();
    membersOnlyModalAnother();
}

function removeModalBarrier () {
	$('.modal-barrier').addClass('done-loading').fadeOut({
		duration: 500,
		always: function () {
			$(this).remove();
		}
	});
}

module.exports = {
	init: init
};
