module.exports = {
    init: function () {
        $('.js-wishlist-delete-form__submit').on('click', function (e) {
            e.preventDefault();
            var confirmation = confirm(Resources.WISHLIST_CONFIRM_DELETE);

            if (confirmation) {
                $(this).siblings('.delete-item').trigger('click');
            }
        });
    }
}
