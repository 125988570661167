'use strict';

var imagesLoaded = require('imagesloaded'),
    util = require('./util'),
	checkMembersOnlyStatus = require('./pages/product/custom/checkMembersOnlyStatus');

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        $('.search-result-content').toggleClass('wide-tiles');
        $(this).toggleClass('wide');
    });
}

function swatchImageCkick() {
    $('.swatch-list').on('mouseleave', function () {
        // Restore current thumb image
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img:not(.product-badge img)').eq(0),
            data = $thumb.data('current');

        if (data && data.hasOwnProperty('src')) {
            $thumb.attr({
                src: data.src,
                alt: data.alt,
                title: data.title
            });
        }
    });
    $('.swatch-list .swatch').on('click touchstart', function (e, data) {
        var isInitialization = data && data.isInitialize ? true : false;
        if (!isInitialization) {
            window.location = $(this).attr('href');
        }
    }).on('mouseenter', function () {
        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img:not(.product-badge img)').eq(0),
            data = $(this).children('img').filter(':first').data('thumb'),
            current = $thumb.data('current'),
			isMembersOnly = $(this).find('.swatch-image').data('ismembersonly'),
			isMembersOnlyType = $(this).find('.swatch-image').data('ismembersonlytype'),
            mainsrc = data.src;
            if (util.getViewport() > 1680) {
                mainsrc = data.srcxl;
            } else if (util.getViewport() > 1160 && util.getViewport() < 1679) {
                mainsrc = data.srclg;
            } else {
                mainsrc = data.src;
            }

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                srcset: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title
            });
        }

        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            src: mainsrc,
            srcset: mainsrc,
            alt: data.alt,
            title: data.title
        });

		// Apply correct styling in case product is members only
		checkMembersOnlyStatus.checkMembersOnlyStatus();
		$tile.removeClass('members-only-product locked members-type-free members-type-premium members-type-military');
		if (isMembersOnly) {
			$tile.addClass('members-only-product members-type-' + isMembersOnlyType);
			$tile.find('.members-only-cta a').attr('href', $(this).attr('href'));
			if ($(this).find('.swatch-image').hasClass('locked')) {
				$tile.addClass('locked');
			}
		}
    });
}

function initUnselectedSwatches() {
    $('.swatch-list').each(function () {
        var $this = $(this);
        if ($this.parents('.order-history-wishlist').length === 0 && $this.find('.swatch').length > 0 && $this.find('.swatch.selected').length === 0) {
            $this.find('.swatch').eq(0).trigger('click', [{isInitialize: true}]);
        }
    });
}

/**
 * @private
 * @function
 * @description Shows product secondary image on product tile hover
 */
function productTileHover() {
    var $searchResults = $('.pt_clubs-search-result, .pt_club-details, .club-pdp-recommendations, .product-family-tabs-section');

    $searchResults.on('mouseenter mouseleave', '.thumb-link', function (e) {
        if (util.getViewport() > 991 && !$(this).closest('.product-tile').hasClass('members-only-product')) {
            var secondaryImg = $(this).data('secondaryImage'),
                mainImage = $(this).find('img:not(.product-badge img)').attr('src');
                if (util.getViewport() > 1680) {
                    secondaryImg = secondaryImg.replace('sw=300&sh=300', 'sw=520&sh=520');
                } else if (util.getViewport() > 1160 && util.getViewport() < 1679) {
                    secondaryImg = secondaryImg.replace('sw=300&sh=300', 'sw=400&sh=400');
                }
            if (secondaryImg !== '') {
                $(this).data('secondaryImage', mainImage);
                $(this).find('img:not(.product-badge img, img.img-secondicon)').attr('src', secondaryImg);
                $(this).find('img:not(.product-badge img, img.img-secondicon)').attr('srcset', secondaryImg);
                e.stopImmediatePropagation();
            }
        }
    });
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    gridViewToggle();
    swatchImageCkick();
    initUnselectedSwatches();
    productTileHover();
    dataLayer.push({'event': 'optimize.activate'});
	checkMembersOnlyStatus.checkMembersOnlyStatus();
}

exports.init = function () {
    initializeEvents();
	$(window).on('load', function () {
		var tileImageHeight = $('.tiles-container .grid-tile[class^="grid-tile tile-"] .product-image').eq(0).outerHeight(true);
		var tileDetailsHeight = $('.tiles-container .grid-tile[class^="grid-tile tile-"] .product-tile-details').eq(0).outerHeight(true);
		var tileHeight = tileImageHeight + tileDetailsHeight;
		var marketingTiles = $('.tiles-container').find('.wide-tile-2x .product-tile');
		if (marketingTiles.length === 0) { return; }
		marketingTiles.css({'height': tileHeight + 'px'});
	});
	$(window).on('resize', function () {
		var tileImageHeight = $('.tiles-container .grid-tile[class^="grid-tile tile-"] .product-image').eq(0).outerHeight(true);
		var tileDetailsHeight = $('.tiles-container .grid-tile[class^="grid-tile tile-"] .product-tile-details').eq(0).outerHeight(true);
		var tileHeight = tileImageHeight + tileDetailsHeight;
		var marketingTiles = $('.tiles-container').find('.wide-tile-2x .product-tile');
		if (marketingTiles.length === 0) { return; }
		marketingTiles.css({'height': tileHeight + 'px'});
	});
};
module.exports.swatchImageCkick = swatchImageCkick;
module.exports.productTileHover = productTileHover;
