'use strict';

var bodyScrollLock = require('body-scroll-lock');
var dialog = require('../../dialog');
const validator = require("../../validator");

/**
 * @description Launch the members only login/register modal
 */

function addPasswordResetModal() {
	$('#password-reset').on('click', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href'),
			options: {
				dialogClass: 'password-reset-dialog',
				width: 480,
				open: function () {
					bodyScrollLock.clearAllBodyScrollLocks();
					bodyScrollLock.disableBodyScroll($('.password-reset-dialog .ui-dialog-content')[0]);
					validator.init();
					var $requestPasswordForm = $('[name$="_requestpassword"]'),
						$submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
					$($submit).on('click', function (e) {
						if (!$requestPasswordForm.valid()) {
							return;
						}
						e.preventDefault();
						dialog.submit($submit.attr('name'));
					});
				},
				close: function () {
					bodyScrollLock.clearAllBodyScrollLocks();
					bodyScrollLock.disableBodyScroll($('.members-only-dialog')[0]);
				}
			}
		});
	});
}

function addPrivacyPolicyModal() {
	$('.privacy-policy').off('click').on('click', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href'),
			options: {
				dialogClass: 'privacy-policy-dialog',
				height: 600,
				width: 480,
				open: function () {
					bodyScrollLock.clearAllBodyScrollLocks();
					bodyScrollLock.disableBodyScroll($('.privacy-policy-dialog .ui-dialog-content')[0]);
					$('#dialog-container').animate({
						scrollTop: 0
					}, '100');
				},
				close: function () {
					bodyScrollLock.clearAllBodyScrollLocks();
					bodyScrollLock.disableBodyScroll($('.members-only-dialog')[0]);
				}
			}
		});
	});
}

module.exports = function () {
	var $membersOnlyModal = $('#product-content').find('.members-only-modal');
	var $selectedSwatch = $('.product-variations').find('.swatches .selected .swatchanchor');

	if ($membersOnlyModal.length === 0) { return; }

	$membersOnlyModal.dialog({
		height: 'auto',
		modal: true,
		overlay: {
			opacity: 0.5,
			background: 'black'
		},
		dialogClass: 'members-only-dialog',
		draggable: false,
		resizable: false,
		width: 960,
		position: {
			my: 'center',
			at: 'center',
			of: window,
			collision: 'flipfit'
		},
		close: function () {
			$membersOnlyModal.dialog('destroy');
			bodyScrollLock.clearAllBodyScrollLocks();
			$(window).trigger('resize');
			$('#password-reset').off('click');
		},
		open: function () {
			bodyScrollLock.disableBodyScroll($('.members-only-dialog')[0]);
			addPrivacyPolicyModal();
			addPasswordResetModal();
		}
	});

	$membersOnlyModal.find('.members-only-account').accordion({
		heightStyle: "content",
		active: $membersOnlyModal.find('.account-register.active').length
	});

    if ($selectedSwatch.length > 0) {
        $membersOnlyModal.find('input[name="returnUrl"]').val($selectedSwatch.attr('href'));
    }  else if ($('.product-variations').length == 0) {
        // set return url for simple product
        $membersOnlyModal.find('input[name="returnUrl"]').val(window.location.href);
    }
};
