'use strict';

var productTile = require('../../product-tile'),
util = require('../../util');

function trimRecos() {
	if(!$('.desktop-recommender')) {
		return;
	}

	if (util.getViewport() <= 880) {
        $('.desktop-recommender').hide();
        $('.mobile-recommender').show();
    } else {
        $('.mobile-recommender').hide();
        $('.desktop-recommender').show();
    }

	$('.tabbed-recommendations').find('.config-master-tabs li').length;
}

function tabsActions() {
	if (!$('.tabbed-recommendations')) {
		return;
	}

	var liCount = $('.tabbed-recommendations').find('.config-master-tabs li').length;
	if (liCount > 0) {
		$('.tabbed-recommendations').find('.config-master-tabs li').each(function () {
			var currentTabName = $(this).attr('data-toggle');
			var currentTabHtmlLength = $('.tabbed-recommendations').find('.' + currentTabName).children().length;
			if (currentTabHtmlLength === 0) {
				$(this).hide();
			}
		});
		var firstVisibleElement = $('.tabbed-recommendations').find('.config-master-tabs li:visible:first');

		if (!firstVisibleElement.hasClass('nav-tab-active')) {
			var tabTog = $(firstVisibleElement).data("toggle");
			//Toggle tab link
			$(firstVisibleElement).addClass('nav-tab-active').siblings().removeClass('nav-tab-active');

			//Toggle target tab
			$('div.' + tabTog).addClass('active').siblings().removeClass('active');
			$('.tabs-view').removeClass('active');
			$('a.view-' + tabTog).addClass('active');
		}
	}
}

function slickRecos() {
	var notifyMe = $('.ui-dialog.notifyme-dialog.notifyme-success');
	if (notifyMe.length > 0) {
		var $hCarousel = notifyMe.find('.club-product-listing-1x3 .tiles-container');
		//init slider
		// do not initialize twice
		if ($hCarousel.hasClass('slick-slider')) {
			return;
		}
		$hCarousel.slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			slide: '.grid-tile',
			vertical: false,
			infinite: false,
			swipe: true,
			arrows: true,
			dots: false,
			touchMove: true,
			focusOnSelect: false,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}
			]
		});
	} else if ($('.tabbed-recommendations, .club-product-listing-1x3').length) {
        $('body').addClass('enable-clp-recommender');
        trimRecos();
		tabsActions();
        $('.tabbed-recommendations-1x5 .tiles-container, .club-product-listing-1x3 .tiles-container').each(function (index, element) {
			var $tCarousel = $(this);
			// do not initialize twice
			if ($tCarousel.hasClass('slick-slider')) {
				return;
			}

			$tCarousel.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				mobileFirst: true,
				slide: '.grid-tile',
				vertical: false,
				infinite: false,
				swipe: true,
				arrows: true,
				dots: true,
				touchMove: true,
				focusOnSelect: false,
				responsive: [
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1023,
						settings: {
							slidesToShow: 4,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 5,
							slidesToScroll: 1
						}
					}
				]
			});
		});
    }
}

exports.init = function () {
    slickRecos();
    productTile.init();
};
