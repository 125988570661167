'use strict';

module.exports.init = function () {
    $('#reason, .js-subreason__select').on('change', function () {
        var value = [];

        value.push($('#reason').val());

        if ($('.js-subreason__select:visible').length) {
            value.push($('.js-subreason__select:visible').val());
        }

        $('#subject').val(value.join(' | '));
    });

    $(document).on('change', '#reason', function () {
        var selectedCategory = $(this).val();
        var $subcategory = $('.js-subcategory');
        $subcategory.hide();
        $subcategory.find('.js-subreason__select').attr('disabled', 'disabled');

        var subCategorySelector = '.js-subcategory[category="' + selectedCategory + '"]';
        var $subCategory = $(subCategorySelector);
        if ($subCategory.length > 0) {
            $subCategory.show();
            $subCategory.find('.js-subreason__select').removeAttr('disabled');
        }

        $('#order-number').hide();
        var showOrderNo = $(this).find('option:selected').attr('showorderno') == 'true';
        if (showOrderNo) {
            $('#order-number').show();
        }
    });

    $(document).on('submit', '.contact-us__form', function (e) {
        $('.recaptcha-custom-error-message').hide();
        $('.g-recaptcha').parent().removeClass('recaptcha-custom-error');

        var response = grecaptcha.getResponse();
        if (response.length === 0) {
            e.preventDefault();
            $('.recaptcha-custom-error-message').show();
            $('.g-recaptcha').parent().addClass('recaptcha-custom-error');
        }
    });

    window.onRecaptchaSuccess = function (response) {
        $('.recaptcha-custom-error-message').hide();
        $('.g-recaptcha').parent().removeClass('recaptcha-custom-error');
    };
}
