var productTile = require('../../../product-tile');
var util = require('../../../util');

/**
 * @description Creates a carousel out of the thumbnails that are setup for each product
 */
var setupCarousel = function () {
    var $mainImageSlider = $('.product-image-container .regular'),
        $thumbnailsSlider = $('.product-image-container .product-thumbnails .carousel');

    var mainConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        infinite: false,
        fade: false,
        swipe: false,
        responsive: [
			{
                breakpoint: 1199,
                settings: {
                    arrows: false,
                    swipe: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                    swipe: true
                }
            }
        ]
    };
    var thumbConfig = {
        mobileFirst: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipe: true,
        arrows: true,
        dots: false,
        focusOnSelect: true,
        responsive: [
			{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };

    if ($mainImageSlider.hasClass('slick-initialized')) {
        $mainImageSlider.slick('unslick');
    }

    if ($thumbnailsSlider.hasClass('slick-initialized')) {
        $thumbnailsSlider.slick('unslick');
        $thumbnailsSlider.off('click', '.slick-slide', function () {});
    }

    // Setup the carousel of thumbnails near the main image
    $thumbnailsSlider.on('init', function () {
        $('.product-thumbnails .carousel .slick-slide.slick-current').addClass('is-active');
        $('.product-image-container').removeClass('loading');
    });

    $mainImageSlider.on('afterChange', function (event, slick, currentSlide) {
        var $shaftAndGripSlides = $mainImageSlider.find('.shaft-img, .grip-img');
        var $thumbSlide = $thumbnailsSlider.find('.thumb').eq(currentSlide);
		var currentNavSlideElem = '.product-thumbnails .carousel .slick-slide[data-slick-index="' + currentSlide + '"]';

        $shaftAndGripSlides.find('.product-image').css('height', $mainImageSlider.outerHeight());

        $thumbnailsSlider.find('.thumb').removeClass('selected').removeClass('slick-current');
        $thumbSlide.addClass('selected').addClass('slick-current');
		$thumbnailsSlider.slick('slickGoTo', currentSlide);

		$('.product-thumbnails .carousel .slick-slide.slick-active').removeClass('slick-active');
		$(currentNavSlideElem).addClass('slick-active');

        if (!$thumbSlide.hasClass('slick-active')) {
            $thumbSlide.trigger('click');
        }
    });

	$thumbnailsSlider.on('click', '.slick-slide', function (event) {
		event.preventDefault();
		var goToSingleSlide = $(this).data('slick-index');
		$mainImageSlider.slick('slickGoTo', goToSingleSlide);
	});

    $mainImageSlider.slick(mainConfig);
    $thumbnailsSlider.slick(thumbConfig);
};

var setupRecCarousel = function () {
    var $recCarousel = $('.club-pdp-recommendations .tiles-container'),
        dots = true;

    $recCarousel.on('init', function () {
        productTile.init();
    });

    if (!$recCarousel) {
        return;
    }

    if ($recCarousel.find('.grid-tile').length < 6 && !util.isMobile()) {
        dots = false;
    }

    $recCarousel.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        slide: '.grid-tile',
        vertical: false,
        infinite: false,
        swipe: true,
        arrows: true,
        dots: dots,
        touchMove: true,
        focusOnSelect: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            }
        ]
    });
};

var stickySlider = function () {
    var $slider = $('.pdp-main .product-primary-image');

    if ($slider.length > 0) {
        $(window).on('scroll', function () {
            if (util.getViewport() <= 991) {
                var bottomPos = $(window).scrollTop();
                var sliderPos = $slider.offset().top;
                if (bottomPos >= sliderPos) {
                    $slider.addClass('sticky');
                } else {
                    $slider.removeClass('sticky');
                }
            }
        });
    }

}

module.exports = {
    setupCarousel: setupCarousel,
    setupRecCarousel: setupRecCarousel,
    stickySlider: stickySlider
}
