'use strict';

var addToCart = require('./addToCart'),
    customize = require('./customize'),
    ajax = require('../../ajax'),
    image = require('./image'),
    progress = require('../../progress'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    dialog = require('../../dialog'),
    util = require('../../util'),
    ordergroove = require('./custom/ordergroove'),
    imageSliders = require('./custom/imageSliders'),
    validator = require('../../validator'),
    checkMembersOnlyStatus = require('./custom/checkMembersOnlyStatus'),
    notifyme = require('../../notifyme'),
    membersOnlyModalAnother = require('./membersOnlyModalAnother');

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var initQuantitySpinner = function () {
    var qtySpinner = $('.qty-spinner');
    var zoomMediaQuery = matchMedia('(max-width: 767px)');
    var changeQtyValue = function () {
        var value = $('#Quantity-spinner').attr('aria-valuenow');
        $('input[name="Quantity"]').val(value);
    };
    qtySpinner.spinner({
        min: 1,
        max: + qtySpinner.data('maxqty')
    });
    $(window).on('resize', function () {
        if (zoomMediaQuery.matches) {
            qtySpinner.prop('disabled', true);
        } else {
            qtySpinner.prop('disabled', false);
        }
    });
    $(window).resize();
    qtySpinner.bind('keydown', function (e) {
        if (zoomMediaQuery.matches) {
            e.preventDefault();
        }
    });
    qtySpinner.on('focus', function () {
        if (zoomMediaQuery.matches) {
            $(this).blur();
        }
    });
    $('.ui-spinner-button').on('click touch', function () {
        qtySpinner.blur();
    });
    qtySpinner.on('blur', function () {
        changeQtyValue();
    });
};

var showSku = function () {
    if ((!$('#add-to-cart').hasClass('disabled') || $('#add-to-cart').hasClass('coming-soon')) && $('.sku-curr').length) {
        $('ul.swatches').addClass('sku-on');
    }
}

var initLearnMoreAccordion = function () {
	var currentAccordionState = sessionStorage.getItem("accordionIsOpen");
	$('.pdp-learn-more--top').accordion({
        heightStyle: 'content',
        collapsible: true,
        active: currentAccordionState == 'false' ? false : 0,
        animate: 200
    });

    $('.product-learnmore--container').removeClass('d-none');

	$('.pdp-learn-more--top .ui-accordion-header').on('click', function () {
		sessionStorage.setItem("accordionIsOpen", $(this).hasClass('ui-state-active'));
	});
}

var checkOptionsAvailability = function ($option) {
    var href = $option.attr('href');
    if (!href) {
        return;
    }

    $.ajax({
        type: 'GET',
        url: util.ajaxUrl(href),
        success: function (response) {
            var $response = $('<div>').html(response);

            var allDisabled = true;

            $response.find('.product-variations ul .attribute:last-child .selectable').each(function () {
                if (!$(this).hasClass('disabled')) {
                    allDisabled = false;
                    return false;
                }
            });

            if (allDisabled) {
                $option.addClass('oos-disabled');
            }
        },
        error: function () {
            console.error('Failed to check options availability');
        }
    });
}

var updateContent = function (href, checkMembersOnly) {
    if (!href) {
        return;
    }
    var allDisabled = true;
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };
    if (window.OG && window.OG.getOptins()) {
        var optins = window.OG.getOptins();
        var productID = $('#pdpMain .og-offer-custom og-offer').attr('product');
        var subOptionSelected = false;
        var optedIn = optins.some(function (item) {
            if (item.product === productID) {
                subOptionSelected = item.tracking_override.every + '_' + item.tracking_override.every_period;
                return subOptionSelected
            }
        });
        params.subOptionSelected = subOptionSelected;
    }
    progress.show($('#pdpMain'));

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback: function () {
            // set base section name with badges into mobile section
            // as mobile section name is not updated after updating content
            // it affects mobile version only ACUO-1241
            if (util.isMobile() && $('.product-base-info .product-section-inner').length > 0 && $('.product-main-info').length > 0) {
                var baseInfo = $('.product-base-info .product-section-inner').html();
                $('.product-main-info').html(baseInfo);
            }

            toggle();
            addToCart();
            customize();
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }
            image.replaceImages();
            tooltip.init();
            imageSliders.setupCarousel();
            initQuantitySpinner();
            image.loadZoom();
            $('#free-returns').on('click', function (e) {
                e.preventDefault();
                dialog.open({
                    url: $(e.target).attr('href')
                });
            });
            validateGiftCard();
            if (window.Klarna) {
                window.Klarna.OnsiteMessaging.refresh()
            }
            // After ajax call validator section doesn't work. Reinit it
            validator.init();
            showSku();
            checkMembersOnlyStatus.checkMembersOnlyStatus();
            initPlayNumberScroll();
            setTimeout(function () {
                $(document).trigger('scroll');
            }, 100);

            $('.product-variations ul .attribute:last-child .selectable').each(function () {
                if (!$(this).hasClass('disabled')) {
                    allDisabled = false;
                    return false;
                }
            });

            if (allDisabled) {
                $('.swatches.hand .selected .swatchanchor').addClass('oos-disabled');
            }

            $('#pdpMain .swatches.hand .selectable:not(.selected) .swatchanchor').each(function () {
                checkOptionsAvailability($(this));
            });

            initLearnMoreAccordion();
            ordergroove.init();
            membersOnlyModalAnother();
        }
    });
};

function expandCustomSelect(e) {
    $('.content:visible').slideUp('slow');
    $(this)
        .next('.content:hidden')
        .slideDown('slow');
}

function toggle() {
    $('.must-select').each(function () {
        $(this).find('.header-toggle').html($(this).find('li.selected').html());
        $(this).find('.content').hide();
    });
    $('.must-select li.selected').closest('.must-select').removeClass('.not-selected').addClass('selected');
    $('.must-select .playNumber:checked').closest('.must-select').removeClass('.not-selected').addClass('selected');
    $('.header').click(expandCustomSelect)

}

var contentAssetsReposition = function () {
    $('.ball-product-pdp-content-assets').detach().insertAfter('#main').addClass('active')
}

function checkRequiredFields(requiredInputs) {
    var isFormCompleted = true;
    requiredInputs.each(function () {
        if ($(this).val() === '') {
            isFormCompleted = false;
            return isFormCompleted;
        }
    });
    return isFormCompleted;
}

function checkEmailFields(emailFields) {
    // we have one input - skipping
    if (emailFields.length < 2) {
        return true;
    }

    if (emailFields[0].value === '' || emailFields[1].value === '') {
        return false;
    }

    return emailFields[0].value === emailFields[1].value;
}

function evaluateGCFields(requiredInputs, emojiField, requiresLetterInputs, emailFields) {
    var enable = checkRequiredFields(requiredInputs);
    var regex = {
        containsLetter: /[A-Za-z]+/,
        email: /^(?!.*\.@)(?!^\.)(?!.*\.{2})[\w.%+-]+@([\w\-]+)((\.(\w){2,}){1,})$/,
        validGiftcardText: /^[a-zA-Z0-9~`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|:;"'<,>\.\? ]*$/,
        validGiftcardSmile: /:\-\)|:\)|:\-\]|:\]|:\-3|:3|:\->|:>|8\-\)|8\)|:\-\}|:\}|:o\)|:c\)|:\^\)|=\]|=\)|:\-D|:D|8\-D|8D|x\-D|xD|X\-D|XD|=D|=3|B\^D|:\-\)\)|:\-\(|:\(|:\-c|:c|:\-< |:<|:\-\[|:\[|>:\[|:\\}|:@|>:\(|:'\-\(|:'\(|:'\-\)|:'\)|D\-':|D:<|D:|D8|D;|D=|DX|:\-O|:O|:\-o|:o|:\-0|8\-0|>:O|:\-\*|:\*|:�|;\-\)|;\)|\*\-\)|\*\)|;\-\]|;\]|;\^\)|:\-,|;D|:\-P|:P|X\-P|x\-p|:\-p|:p|:\-b|:b|d:|=p|>:P|:\-\.|:L|=L|:S|:\$|:\-X|:X|:\-#|:#|:\-&|:&|O:\-\)|O:\)|0:\-3|0:3|0:\-\)|0:\)|0;\^\)|>:\-\)|>:\)|\}:\-\)|\}:\)|3:\-\)|3:\)|>;\)|:\-J|#\-\)|%\-\)|%\)|:\-###\.\.|:###\.\.|',:\-l|<_<|>_>|@\};\- |@\}\->\-\- |@\}\-;\-'\-\-\- |@>\-\->\-\-|5:\-\)|~\(_8\^\(I\)|=:o\]|7:\^\]|,:\-\)|<3|><>|<\*\)\)\)\-\\}|><\(\(\(\*>|v\.v|O_O|o\-o|O_o|o_O|o_o|O\-O|>\.<|\^5|>_>\^ \^<_<|\( \^\^\)|_U~~|\( \^\^\)|\(Q\)\)|\)\)><<|<\*\)\)|>=<|\(>_<\)|\(>_<\)>|\(';'\)|\(\^_\^;\)|\(\-_\-;\)|\(~_~;\)|\^\^;|\^_\^;|\(#\^\.\^#\)|\(\^\^;\)|\(\-_\-\)zzz|\(\^_\-\)|\(\(\+_\+\)\)|\(\+o\+\)|\^_\^|\(\^o\^\)|\(__\)|_\(\._\.\)_|_\(_\^_\)_|<\(_ _\)>|<m\(__\)m>|m\(__\)m|m\(_ _\)m|\('_'\)|\(T_T\)|\(;_;\)|\(;_;|\(;_:\)|\(;O;\)|\(:_;\)|\(ToT\)|;_;|;\-;|;n;|;;|Q\.Q|T\.T|TnT|QQ|Q_Q|\(\-\.\-\)|\(\-_\-\)|\(=_=\)|\(=\^�\^=\)|\(=\^��\^=\)|=_\^=|\(\.\.\)|\(\._\.\)|\^m\^|\(\-"\-\)|\(\^0_0\^\)|\( \^\)o\(\^ \)|\(\^O\^\)|\(\^o\^\)|\(\^?\^\)|\)\^o\^\(|:O |o_O|o_0|o\.O|\(o\.o\)|oO|uwu|UwU|\(\?_\?\)|>\^_\^<|<\^!\^>|\(\*\^0\^\*\)|\(\*_\*\)|\(\*_\*;|\(\+_\+\)|\(@_@\)|!\(\^\^\)!|\(\*\^\^\)v|\(p_\-\)|\(\(d\[\-_\-\]b\)\)|\(\^\^\)v|\(\^_\^\)v|\(~o~\)|\(~_~\)/g
    };
    requiresLetterInputs.each(function () {
        var testValue = $(this).val();
        if (!regex.containsLetter.test($.trim(testValue))) {
            enable = false;
        }
    });
    if (!enable) {
        return false;
    }
    if (!checkEmailFields(emailFields)) {
        return false;
    }
    if (emailFields.length > 1 && (!regex.email.test(emailFields[0].value) || !regex.email.test(emailFields[1].value))) {
        return false;
    } else if (emailFields.length === 1 && !regex.email.test(emailFields[0].value)) {
        return false;
    }
    var value = $(emojiField).val();
    var isValid = regex.validGiftcardText.test($.trim(value));
    if (!isValid) {
        return false;
    } else {
        if ($.trim(value).match(regex.validGiftcardSmile)) {
            var result = $.trim(value).match(regex.validGiftcardSmile);
            window.console.log(result);
            return false;
        }
    }
    return enable;
}

function validateGiftCard() {
    var $gcForm = $('#pdpMain .gcForm');
    if ($gcForm.length > 0) {
        var $addGCToCart = $('#add-gc-to-cart');
        var $requiredInputs = $gcForm.find('.required');
        var $requiresLetterInputs = $gcForm.find('.requiresLetter');
        var $emailFields = $gcForm.find('.gift-egc-email-confirm');
        var $perMsg = $gcForm.find('textarea.emojiless');
        // This checks anything entered into the textarea.
        $perMsg.on('input', function () {
            var enableA2C = evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs, $emailFields);
            if (enableA2C && !$('#giftcardDeliveryDate').hasClass('error')) {
                $addGCToCart.removeClass('disabled');
            } else {
                $addGCToCart.addClass('disabled');
            }
        });
        $('#giftcardDeliveryDate').on('blur', function () {
            var enableA2C = evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs, $emailFields);
            if ($(this).valid() && enableA2C) {
                $('#add-gc-to-cart').removeClass('disabled');
            } else {
                $('#add-gc-to-cart').addClass('disabled');
            }
        });
        $requiredInputs.on('change', function () {
            if (evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs, $emailFields) && !$('#giftcardDeliveryDate').hasClass('error')) {
                $addGCToCart.removeClass('disabled');
            } else {
                $addGCToCart.addClass('disabled');
            }
        });
        $('#giftcardPurchaseAmount').on('input', function () {
            $('#giftcardPurchaseAmountOption').val('');
            if ($(this).valid() && $(this).val() !== '') {
                $('#giftcardPurchaseAmountOption').removeAttr('required').removeClass('required').blur();
                $requiredInputs = $gcForm.find('.required');
                if (evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs, $emailFields) && !$('#giftcardDeliveryDate').hasClass('error')) {
                    $addGCToCart.removeClass('disabled');
                } else {
                    $addGCToCart.addClass('disabled');
                }
            } else {
                $('#giftcardPurchaseAmountOption').attr('required', 'required').addClass('required');
                $requiredInputs = $gcForm.find('.required');
                $addGCToCart.addClass('disabled');
            }
        });
        $('#giftcardPurchaseAmountOption').on('change', function () {
            $('#giftcardPurchaseAmount').val('').removeClass('error').attr('aria-invalid', 'false');
            $('#giftcardPurchaseAmount-error').hide();
            $(this).attr('required', 'required').addClass('required');
            $requiredInputs = $gcForm.find('.required');
            if (evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs, $emailFields) && !$('#giftcardDeliveryDate').hasClass('error')) {
                $addGCToCart.removeClass('disabled');
            } else {
                $addGCToCart.addClass('disabled');
            }
        });
        $('.gift-egc-email-confirm').on('change', function () {
            $requiredInputs = $gcForm.find('.required');
            var isValid = $(this).valid();

            // if this one is valid - e.g. both emails equal - we need validate another field too
            if (isValid) {
                var $that = $(this)[0];
                // filtering for another field
                var secondEmailInput = $('.gift-egc-email-confirm').filter(function (idx, element) {
                    return element.id != $that.id;
                });
                // and if it's not an edge case - let's validate it
                if (secondEmailInput.length === 1 && secondEmailInput.val() !== '') {
                    secondEmailInput.valid();
                }
            }
            if (evaluateGCFields($requiredInputs, $perMsg, $requiresLetterInputs, $emailFields) && isValid) {
                $addGCToCart.removeClass('disabled');
            } else {
                $addGCToCart.addClass('disabled');
            }
        });
    }
}

module.exports = function () {


    var $pdpMain = $('#pdpMain');
    var timeOut;
    var allDisabled = true;

    notifyme.init();

    $('.product-variations ul .attribute:last-child .selectable').each(function () {
        if (!$(this).hasClass('disabled')) {
            allDisabled = false;
            return false;
        }
    });

    if (allDisabled) {
        $('.swatches.hand .selected .swatchanchor').addClass('oos-disabled');
    }

    $('#pdpMain .swatches.hand .selectable:not(.selected) .swatchanchor').each(function () {
        checkOptionsAvailability($(this));
    });
	sessionStorage.setItem("accordionIsOpen", $('.showwhos-hide').length ? false : true);
    initQuantitySpinner();
    contentAssetsReposition();
    initLearnMoreAccordion();


    // hover on swatch - should update main image with swatch image
    if ($('.giftcard-pdp').length > 0) {
        if (window.innerWidth > 991) {
            $('.giftcard-pdp').addClass('desktop-viewport');
        } else {
            $('.giftcard-pdp').removeClass('desktop-viewport');
        }

        $(document).on('mouseenter mouseleave', '.giftcard-pdp.desktop-viewport .swatchanchor', function () {
            var largeImg = $(this).data('lgimg'),
                $imgZoom = $pdpMain.find('.main-image.slick-current'),
                $mainImage = $pdpMain.find('.slick-current .primary-image');

            if (!largeImg) { return; }
            // store the old data from main image for mouseleave handler
            $(this).data('lgimg', {
                hires: $imgZoom.attr('href'),
                url: $mainImage.attr('src'),
                alt: $mainImage.attr('alt'),
                title: $mainImage.attr('title')
            });

            // set the main image
            image.setMainImage(largeImg);
        });
    } else {
        $pdpMain.on('mouseenter mouseleave', '#pdpMain .swatchanchor', function () {
            var largeImg = $(this).data('lgimg'),
                $imgZoom = $pdpMain.find('.main-image.slick-current'),
                $mainImage = $pdpMain.find('.slick-current .primary-image');

            if (!largeImg) { return; }
            // store the old data from main image for mouseleave handler
            $(this).data('lgimg', {
                hires: $imgZoom.attr('href'),
                url: $mainImage.attr('src'),
                alt: $mainImage.attr('alt'),
                title: $mainImage.attr('title')
            });

            // set the main image
            image.setMainImage(largeImg);
        });
    }

    $(window).on('resize', function () {
        if ($('.giftcard-pdp').length > 0) {
            if (window.innerWidth > 991) {
                $('.giftcard-pdp').addClass('desktop-viewport');
            } else {
                $('.giftcard-pdp').removeClass('desktop-viewport');
            }
        }
    });

    // click on swatch - should replace product content with new variant
    $pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        if ($(this).hasClass('disabled')) { return; }
        updateContent(this.href, true);
    });
    $pdpMain.on('click', '#add-to-cart', function () {
        if ($(this).hasClass('disabled') || $(this).is(':disabled') || $(this).attr('notSelectedVariant') != undefined) {
            var $unselectedVariants = $('.must-select:not(.selected)');
            if ($unselectedVariants.length > 0) {
                $('.selection-error').show();
            }
        }
    });

    $pdpMain.on('click', '#add-to-cart', function (e) {
        if ($(this).hasClass('disabled') || $(this).is(':disabled')) {
            e.preventDefault();
            $(this).blur();
        }
    });

    $pdpMain.on('click', '.product-detail .custom-select li', function () {
        updateContent($(this).attr('value'));
    });

    $pdpMain.on('keyup', '#special-number', function (e) {
        var $this = $(this);
        clearTimeout(timeOut);
        timeOut = setTimeout(function () {
            if ((e.keyCode > 41 || e.keyCode < 35) && $this.val() !== '') {
                $this.change();
            }
        }, 750);
    });

    $pdpMain.on('keydown', '#special-number', function () {
        clearTimeout(timeOut);
    });

    $pdpMain.on('change', '#SpecialPlayNumber, #playNumber', function () {
        if ($(this).val() !== '') {
            updateContent($(this).val());
        } else {
            $('#add-to-cart').addClass('disabled');
        }
    });

    $pdpMain.on('change', '.playNumber', function () {
        if ($(this).attr('data-href') && $(this).attr('data-href') !== '' && $(this).val() !== '') {
            var url = $(this).attr('data-href');
            url = url.replace($(this).attr('name') + '=', $(this).attr('name') + '=' + $(this).val());
            if ($(this).valid()) {
                updateContent(url);
            } else {
                $('#add-to-cart').addClass('disabled');
            }
        } else if ($(this).val() != '') {
            updateContent($(this).val());
        } else {
            $('#add-to-cart').addClass('disabled');
        }
    });

    $pdpMain.on('click', '#design-your-own-ball', function (e) {
        e.preventDefault();
        var href = $(e.currentTarget).attr('href');
        if ($('.optin-btn og-optin-button').is('[subscribed]')) {
            var optins = window.OG.getOptins();
            var productID = $('#pdpMain .og-offer-custom og-offer').attr('product');
            var subOptionSelected = false;
            var optedIn = optins.some(function (item) {
                if (item.product === productID) {
                    subOptionSelected = item.tracking_override.every + '_' + item.tracking_override.every_period;
                    return subOptionSelected
                }
            });
            var qty = $('.pdpForm').find('[name="Quantity"]').first().val();

            var params = {
                subOptionSelected: subOptionSelected,
                Quantity: isNaN(qty) ? '1' : qty
            };

            href = util.appendParamsToUrl(href, params);
        }
        window.location.replace(href);
    });

    $pdpMain.on('click', '#numbers-special', function () {
        var $this = $(this);
        if ($this.is(':checked')) {
            $('#numbers-special-value').removeClass('visually-hidden');
            $('#special-number').addClass('required');
            $this.closest('.radio-buttons').removeClass('selected');
            $this.parents('.must-select').addClass('accessories-shown');
            initPlayNumberScroll();
        } else {
            $('#numbers-special-value').addClass('visually-hidden');
            $this.parents('.must-select').removeClass('accessories-shown');
            $('#special-number').removeClass('required');
        }
    });

    $pdpMain.on('click', '.headwear-personalize', function () {
        if ($(this).val() == 'Yes') {
            $(this).parents('.pdpForm').find('.product-add-to-cart #add-to-cart').addClass('visually-hidden');
        } else {
            $(this).parents('.pdpForm').find('.product-add-to-cart #add-to-cart').removeClass('visually-hidden');
        }
        $(this).closest('.radio-buttons').removeClass('not-selected').addClass('selected');
        if ($('.must-select.not-selected').length == 0) {
            $('.selection-error').hide();
        }
    });

    $pdpMain.on('keydown', '.product-detail .custom-select li', function (e) {
        var key = e.keyCode ? e.keyCode : e.which;
        var KEY_CODE_ENTER = 13,
            KEY_CODE_SPACE = 32;
        if ([KEY_CODE_ENTER, KEY_CODE_SPACE].indexOf(key) > -1) {
            e.preventDefault();
            updateContent($(this).attr('value'));
        }
    });

    $pdpMain.on('click', '.product-detail #play-hand .button', function () {
        var btn = $(this);
        if (!btn.hasClass('disabled')) {
            $('#va-orientation option').each(function () {
                if ($(this).html().trim() === btn.data('attr-value')) {
                    updateContent($(this).attr('value'));
                }
            });
        }
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.on('change', '.variation-select', function (e) {
        e.preventDefault();
        if ($(this).val().length === 0) { return; }
        updateContent($(this).val());
    });

    $pdpMain.on('keydown', '.header', function (e) {
        var key = e.keyCode ? e.keyCode : e.which;
        var KEY_CODE_ENTER = 13,
            KEY_CODE_SPACE = 32,
            actionKeys = [KEY_CODE_ENTER, KEY_CODE_SPACE];
        if (actionKeys.indexOf(key) > -1) {
            e.preventDefault();
            expandCustomSelect.call(this);
        }
    });

    toggle();
    showSku();

    var $pdpContentAssets = $('.ball-product-pdp-content-assets');
    var pdpContentAssets = $pdpContentAssets.html();
    if (pdpContentAssets) {
        $(pdpContentAssets).insertAfter('#main');
    }
    $pdpContentAssets.remove();

    $('.carousel-home .m-hero-wrapper').show();
    var heroCarouselSlider = $('.carousel-home');
    var autoplaySpeed = heroCarouselSlider.children().first().attr('data-autoplaySpeed');
    heroCarouselSlider.slick({
        "autoplaySpeed": parseInt(autoplaySpeed),
        infinite: true,
        speed: 800,
        fade: true,
        cssEase: 'linear',
        adaptiveHeight: true
    });

    validateGiftCard();
    // Run validation GC fields after loading page.
    $('#giftcardPurchaseAmount').trigger('input'); // Remove required from field giftcardPurchaseAmountOption if giftcardPurchaseAmount has value
    $('#pdpMain .gcForm .required').last().trigger('change'); // run validation for gc fields

    $(document).on('click', '.js-play-number__toggle', function () {
        $(this).toggleClass('active');
        $('.js-play-number__scroll-wrapper').toggleClass('active');
    });

    initPlayNumberScroll();

    $pdpMain.on('click', 'input[name="custom-number"]', function () {
        var selectedPlayNumber = $('input[name="custom-number"]:checked').val();
        $('input#special-number').val(selectedPlayNumber).change();
    });
};

var initPlayNumberScroll = function () {
    var $slider = $('.js-play-number__scroll');

    if ($slider.length && $slider.is(':visible')) {
        var containerWidth = $slider.width();

        if ($('.js-play-number__scroll .play-number__page').length > 1) {
            $slider.mCustomScrollbar('destroy');

            var position = $slider.find('.play-number__checkbox:checked').parents('.play-number__page').index();
            var selectedLogoScroll = position > -1 ? position * containerWidth * -1 + 'px' : 0;

            $slider.mCustomScrollbar({
                axis: "x",
                setLeft: selectedLogoScroll,
                scrollButtons: {
                    enable: true,
                    scrollAmount: containerWidth,
                    scrollType: "stepped"
                },
                mouseWheel: {
                    enable: true,
                    deltaFactor: containerWidth,
                    scrollAmount: containerWidth
                },
                contentTouchScroll: containerWidth,
                advanced: {
                    autoExpandHorizontalScroll: true,
                    updateOnImageLoad: false,
                    autoScrollOnFocus: false
                },
                callbacks: {
                    whileScrolling: function () {
                        if ($('.tooltip-content--play-number').is(":visible")) {
                            $('.tooltip-content--play-number').hide();
                        }
                    }
                }
            });

            $slider.find('.mCSB_buttonRight').on('touchstart', function () {
                var position = '-=' + $slider.find('.play-number__page:first').outerWidth();
                $slider.mCustomScrollbar('scrollTo', position)
            });

            $slider.find('.mCSB_buttonLeft').on('touchstart', function () {
                var position = '+=' + $slider.find('.play-number__page:first').outerWidth();
                $slider.mCustomScrollbar('scrollTo', position)
            });
        } else {
            $slider.mCustomScrollbar('destroy');
        }
    }
}
