'use strict';
var util = require('../../util');

function viewport() {
	var e = window, a = 'inner';
	if (!('innerWidth' in window)) {
		a = 'client';
		e = document.documentElement || document.body;
	}
	return {width: e[a + 'Width'] , height: e[a + 'Height']};
}

var stickyBall = function () {
    var desktopSticky = function () {
        var $anchor = $('#pdpMain .product-core-info'),
        $controls = $('#product-content'),
        $ball = $('.product-image-container'),
        $ballHeight = $('.product-image-container').outerHeight(true),
		$ballOuter = $ball.closest('.product-image-container-outer'),
		$productDetailsHeight = $('.product-col-2.product-detail').outerHeight(true),
        vp = viewport(),
        $window = $(window),
		st,
		ot,
		ob,
		bh;
        if ($anchor.length && $window.length && $ball.length) {
            st = $window.scrollTop();
            ot = $anchor.offset().top;
            ob = ot + $anchor.height(); //offest.top of bottom of pdpMain
			if ($productDetailsHeight > $ballHeight) {
				if ($('.product-image-container-height').length === 0) {
					$ball.parent().append('<span class="product-image-container-height"/>');
				}
				if ($ball.closest('#QuickViewDialog').length > 0) {
					$ball.css({'max-width': $ballOuter.outerWidth(true)});
				}
				if (st > ot) {
					$ball.addClass('sticky');
					bh = $ball.outerHeight() + 15;
					if (st < ob - bh) {
						$ball.css('top', '0px');
						$ball.removeClass('scroll-parked');
					} else {
						$ball.css('top', 'auto');
						$ball.addClass('scroll-parked');
                        $ball.css('top', ob - st - $ballHeight);
					}
					$('.product-image-container-height').css({'min-height': $ballHeight}).addClass('show');
				} else {
					$ball.removeClass('sticky scroll-parked');
					$ball.css('top', '');
					$('.product-image-container-height').css({'min-height': $ballHeight}).removeClass('show');
				}
			} else {
				$('.product-image-container-height').remove();
				$ball.removeClass('sticky scroll-parked');
				$ball.css({'top': '', 'max-width': ''});
			}
        }
    }

    $(window).on('scroll', function () {
        var $slider = $('.product-primary-image');

        if ($slider.length > 0) {
            var $mainImage = $slider.find('.primary-image');
            var $thumbs = $slider.find('.product-thumbnails');

            if (util.getViewport() > 768) {
                desktopSticky();
            }

            if ($slider.hasClass('sticky-mobile')) {
                $mainImage.css('height', $slider.outerHeight() - $thumbs.outerHeight());
                $slider.css('height', 'auto');
                $slider.removeClass('sticky-mobile');
                setTimeout(function () {
                    $mainImage.css('height', 'auto')
                }, 2000);
            }
        }
    });
	$(window).on('resize', function () {
		if (util.getViewport() > 768) {
			desktopSticky();
		} else {
			$('.product-image-container').css({'top': '', 'max-width': ''}).removeClass('sticky');
		}
	});
};

exports.init = function () {
    stickyBall();
};
