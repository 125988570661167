'use strict';

var ordergroove = require('./product/custom/ordergroove');

var orderconfirmation = {
    init: function () {
        ordergroove.clear();
    }
};

module.exports = orderconfirmation;
