'use strict';

var util = require('./util');

var headermenu = {
    init: function () {
        $(function () {

            bindDesktopEvents();

            bindMobileEvents();

            window.addEventListener("hashchange", handleHashChange);

            resetNav(0);

            loadCustomerMenu();

            $(window).on('resize', function (evt) {
                resetNav(1);
            });

            $('#login-form').on('submit', function () {
                loadCustomerMenu();
            });

			$('.account-toggle .icon-person').on('click', function (e) {
				if ($('.header-kr').length == 0) {
					e.preventDefault();
					$('.account-toggle').toggleClass('is-open');
					if ($('.account-menu.logged-in').length && $('.account-menu.logged-in').val().trim() == '') {
						var isMenuNotLoaded = $('.account-menu').html().trim() == '';
						if (isMenuNotLoaded) {
							loadCustomerMenu();
						}
					}
					if ($('.pt_homepage').length) {
						return false;
					}
				}
			});

            function resetNav(flag) {
                // replaced following check Modernizr.mq('(min-width: 940px)')
                if (window.innerWidth > 940) {
                    var desktopNavAlreadySet = setupDesktopNav();
                    if (!desktopNavAlreadySet) {
                        if (flag == 1)
                            bindDesktopEvents();
                    }
                } else {
                    setupMobileNav();
                    unbindDesktopEvents();
                }
            }

            function setupMobileNav() {
                $('#main-nav').addClass('mobile');
                $('#main-nav').removeClass('desktop');
                $('#main-nav > ul:not(.helpful-links) > li > ul,#main-nav > ul > li > ul > li ul.sub-nav-menu').each(function () {
                    if ($(this).find('.back-link').length == 0) {
                        var menuHeader = $(this).parent().find('a').html();
                        $(this).prepend('<li class="back-link"><h3><span class="titleist-icon icon-carousel-left"></span>' + menuHeader + '</h3></li>');
                    }
                });
                $('#main-nav > ul > li > ul').on('click', '.back-link', function () {
                    $(this).parent().parent().removeClass('open');
                });
            }

            function setupDesktopNav() {
                if ($('#main-nav').hasClass('desktop'))
                    return true;

                $('#main-nav').addClass('desktop');
                $('#main-nav').removeClass('mobile');

                return false;
            }

            function openMenuDesktop($menu, $drop, focusFirstLink) {
                var $subMenu = $menu.find('> .sub-nav-menu');
                if (window.navTimer !== undefined)
                    clearTimeout(window.navTimer);
                if (!$subMenu.hasClass('open')) {
                    $drop.find('> .sub-nav-menu').removeClass('open').css('display', '');
                    $subMenu.addClass('open');
                    if (focusFirstLink) {
                        $subMenu.find('a').first().focus();
                    }
                }
            }

            function closeMenuDesktop($drop) {
                var $input = $drop.find('input[type="search"]');
                if ($input !== undefined && $input.length > 0) {
                    if ($input.is(':focus')) {
                        return;
                    }
                }

                window.navTimer = setTimeout(function () {
                    $drop.find('> .sub-nav-menu').fadeOut(100, function () {
                        $(this).removeClass("open");
                    });
                }, 100);
            }

            function bindDesktopEvents() {
                var topNav = $('#main-nav'),
                   drop = topNav.find('> ul > .nav-item');

                drop.on('keydown', ' > a', function (evt) {
                    //down arrow
                    if (evt.keyCode == 40) {
                        evt.preventDefault();
                        openMenuDesktop($(this).parent(), drop, true);
                    }
                });

                drop.on('keydown', function (evt) {
                    //up arrow
                    if (evt.keyCode == 38) {
                        evt.preventDefault();
                        var $openMenu = $('.sub-nav-menu.open');
                        if ($openMenu.length > 0) {
                            closeMenuDesktop($openMenu.parent());
                            $openMenu.siblings('a').focus();
                        }
                    }
                });

                drop.mouseleave(function () {
                    closeMenuDesktop(drop);
                });

                function openUtilityNav($nav) {
                    if (!$nav.hasClass('is-open')) {
                        $nav.parent().find('.is-open').removeClass('is-open').attr('aria-hidden', true).removeAttr('tabindex');
                        $nav.addClass('is-open');
                        $nav.find('ul').attr('aria-hidden', false).attr('tabindex', 0).focus();
                    }
                }

                function closeUtilityNav($nav) {
                    if ($nav.hasClass('is-open')) {
                        $nav.find('ul').removeAttr('style').attr('aria-hidden', true).removeAttr('tabindex');
                        $nav.removeClass('is-open');
                    }
                }

                $('#utility-nav > .nav-util-list').on({
                    mouseenter: function () {
                        openUtilityNav($(this));
                    },
                    mouseleave: function () {
                        closeUtilityNav($(this));
                    }
                }, '.nav-util-item.has-children');

                $('#utility-nav > .nav-util-list > .nav-util-item.has-children').on('keydown', function (evt) {
                    //down arrow
                    if (evt.keyCode == 40) {
                        evt.preventDefault();
                        openUtilityNav($(this));
                    } else if (evt.keyCode == 38) { //up arrow
                        evt.preventDefault();
                        closeUtilityNav($(this));
                        $(this).children('a').focus();
                    }
                });

                $('#header-account-link').on('click', function (e) {
                    e.preventDefault();
                });
            }

            function bindMobileEvents() {
                $('#mobile-nav-icon-wrapper').on('click', function (evt) {
                    $(this).find('#mobile-nav-icon').toggleClass('open');

                    if ($('#main-nav').hasClass('open')) {
                        $('#main-nav').removeClass('open');
                        $('body').removeClass("mobile-fixed-position");
                    } else {
                        $('#main-nav').addClass('open');
                        $('body').addClass("mobile-fixed-position");
                    }
                });

                $('header').on('click', '#main-nav.mobile .nav-item > a,#main-nav.mobile .sub-nav-menu a.sub-nav-header', function (evt) {
                    if ($(this).parent().find('.sub-nav-menu').length > 0) {
                        evt.preventDefault();
                        if ($(this).parent().hasClass('open')) {
                            $(this).parent().removeClass('open');
                        } else {
                            $(this).parent().addClass('open');
                        }
                    }
                });
                $('#mobile-search').on('click', 'a', function (evt) {
                    evt.preventDefault();
                    if ($('nav.primary').hasClass('is-animated')) {
                        $('html, body').animate({scrollTop: '0px'}, 200);
                    }
                    // Close/open search box.
                    $(this).parent().toggleClass('is-open');
                    $('.titleist-search-box-cta').toggleClass('active');
                    $('.titleist-search-box').toggleClass('active');
                });
            }

            function unbindDesktopEvents() {
                $('#main-nav > ul > .nav-item').off('mouseenter');
                $('#main-nav > ul > .nav-item').off('mouseleave');
                $('#main-nav > ul > .nav-item > .sub-nav-menu').removeAttr('style');
                $('#main-nav > ul > .nav-item > .sub-nav-menu, #main-nav > ul > .nav-item > .sub-nav-menu .sub-nav-col > li').removeClass('open');
            }

            function handleHashChange(event) {
                var currentHash = location.hash;

                var mixFilter = '';
                if (currentHash.indexOf('#cat-') > -1) {
                    var category = '.' + currentHash.replace('#cat-', '');
                    if ($('.mix-menu [data-filter="' + category + '"]').length > 0) {
                        $('.mix-menu li a').removeClass('active');
                        $('.mix-menu [data-filter="' + category + '"]').addClass('active');
                        mixFilter = category;
                    }

                    $('.mix-container').mixItUp('filter', mixFilter);

                    //Close desktop hover menu when a new category hash is clicked from the top menu
                    $('#main-nav.desktop > ul > .nav-item').find('> .sub-nav-menu').fadeOut(100, function () {
                        $(this).removeClass("open");

                    });

                    closeMobileMenu();

                    //scroll to the location of the category content
                    $('html, body').animate({scrollTop: $('.mix-menu').position().top - 12}, 'slow');
                } else {
                    var hashName = currentHash.substring(1, currentHash.length);
                    if ($(currentHash).length > 0 || $('a[name="' + hashName + '"]').length > 0) {
                        closeMobileMenu();

                        var $target = {};
                        if ($(currentHash).length > 0) {
                            $target = $(currentHash);
                        } else {
                            $target = $('a[name="' + hashName + '"]');
                        }

                        var device;
						if (window.innerWidth < 992) {
							device = 10;
						}
						if (window.innerWidth > 991) {
							device = 91;
						}
						$('html, body').animate({scrollTop: $target.offset().top - device}, 'slow');
                    }
                }
            }

            function closeMobileMenu() {
                //Close mobile menu when a new category hash is clicked from the top menu
                $('#mobile-nav-icon-wrapper').find('#mobile-nav-icon').removeClass('open');
                $('#main-nav').removeClass('open');
                if ($('#main-nav').hasClass('open')) {
                    $('body').addClass("mobile-fixed-position");
                } else {
                    $('body').removeClass("mobile-fixed-position");
                }
            }

            function loadCustomerMenu() {
                var path = $('.account-toggle').attr('data-cms-path');
                var reqUrl = window.location.protocol + "//" + window.location.hostname + '/' + path;
                if ($('.pt_wish-list').length) {
                    $(this).parent().toggleClass('is-open');
                }
                if (!$('.logged-out').length) {
                    $.ajax({
                        type: 'GET',
                        url: reqUrl,
                        dataType: 'html',
                        success: function (data) {
                            $('.account-menu').html(data);
                        }
                    });
                }
            }
        });

        // on hover and on focus handler, resets fade timer and handle focus action
        var myTimer, drop = $('#main-nav > ul > .nav-item');
        var onHover = function (e) {
            clearTimeout(myTimer);
            // console.log(e.type, this);
            var $this = $(this).hasClass('menu-item-toggle') ? $(this).parent('li.nav-item') : $(this);
            var firstFocusable = $this.find('a').first()[0];
            if (e.type != 'focusin' && firstFocusable && (!document.activeElement || !$.contains($this[0], document.activeElement))) {
                firstFocusable.needFocus = true;
                firstFocusable.focus();
                // added check and return after focus triggered, it can fail
                if (!firstFocusable.needFocus) return console.log('prevent ', e.type);
            }
            // set flag to mark focus
            this.needFocus = false;
            var subMenu = $this.find('> .sub-nav-menu');
            if (!subMenu.hasClass('open')) {
                // console.log(e.type, 'action');
                drop.find('> .sub-nav-menu').removeClass('open').css('display', '');
                subMenu.addClass('open');
                $this.addClass('open');
            }
        };
        // mouse out and focus out handlerthat trigger timer to delay hiding of menu item
        var unHover = function (e) {
            var $this = $(this).hasClass('menu-item-toggle') ? $(this).parent('li.nav-item') : $(this);
            // console.log(e.type, e.relatedTarget, $(e.relatedTarget).parents('.open'));
            // prevent unhover if we are no moving from that tab
            if ($(e.relatedTarget).parents('.open').length) {
                return;
            }
            $this.removeClass("open");

            var $input = drop.find('input[type="search"]');
            if ($input !== undefined && $input.length > 0) {
                if ($input.is(':focus')) {
                    return;
                }
            }

            myTimer = setTimeout(function () {
                drop.find('> .sub-nav-menu').fadeOut(100, function () {
                    $(this).removeClass("open")
                });
            }, 100);
        }

        // trigger focus and mouse hover events
        if (window.innerWidth > 940) {
            drop.on('focus', 'a.has-sub-menu', onHover);
            drop.on('focusout', unHover);
            drop.mouseover(onHover);
            drop.mouseout(unHover);
        }

        $('.open-search-overlay').on('click', function () {
            $('html').toggleClass('searchit-open');
            if (util.getViewport() >= 880) {
                $('#q').focus();
            }
        });

        $(window).on('click', function (e) {
            var container = $(".account-menu, .account-toggle");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $('.account-toggle').removeClass('is-open');
            }
        });
    }
};

module.exports = headermenu;
