var util = require('../../../util'),
    TPromise = require('promise'),
    imagesLoaded = require('imagesloaded');

var updateComparedProduct = function (productSelector) {
	var url = util.appendParamsToUrl(Urls.clubCompareProductUpdate, {
		'pid': productSelector.val(),
		'mainpid': $('.product-add-to-cart #pid').val()
	});
	return TPromise.resolve($.ajax({
		type: 'GET',
		url: util.ajaxUrl(url)
	}));
};


var syncCompareHeight = function () {
    imagesLoaded('#section-compare').on('always', function () {
        $('.compare-table:first').children().each(function (index, item) {
            if (!$(item).hasClass('compare-prod-selector')) {
                var $leftItem = $(item);
                var $rightItem = $('.compare-table:last').children().eq(index);

                $leftItem.css('height', 'auto');
                $rightItem.css('height', 'auto');

                setTimeout(function () {
                    var height = $leftItem.outerHeight() > $rightItem.outerHeight() ? $leftItem.outerHeight() : $rightItem.outerHeight();
                    $leftItem.css('height', height);
                    $rightItem.css('height', height);
                }, 0)
            }
        });
    });
}

var hideShopNow = function ($section) {
    var $link = $section.find('.js-compare__shop-now');

    if ($link.data('pid') === $('#pid').val() || $link.data('masterid') === $('#masterid').val()) {
        $link.parents('.shop-now-row').addClass('hidden');
    }
}

module.exports = {
    init: function () {
        var $pdpMain = $('.club-pdp-main');

        $('.compare-table').each(function () {
            hideShopNow($(this))
        });

        $pdpMain.off('change', '.compare-prod-selector').on('change', '.compare-prod-selector', function (e) {
            var target = $(e.currentTarget);
            updateComparedProduct($(this)).then(function (response) {
                var $section = target.closest('.compare-table');

                $section.html(response);
                hideShopNow($section);
                syncCompareHeight();
            });
        });

        syncCompareHeight();
        util.smartResize(function () {
            syncCompareHeight();
        });
    }
}
