var dialog = require('../../../dialog');

function updatePDP(optedIn) {
    var ogOfferContainer = $('.og-offer-custom');

    if ($('.optin-btn og-optin-button').is(':visible')) {
        if (['cart', 'update', 'subscription'].indexOf($('.add-to-cart').data('action')) === -1) {
            $('.subscribeBtnText').toggle(optedIn);
            $('.addToCartBtnText').toggle(!optedIn);
            if ($('.edit-product-modal:visible').length || $('#add-to-cart').attr('data-action') == 'update') {
                $('.subscribeBtnText, .addToCartBtnText').toggle(false);
                $('.updateBtnText').toggle(true);
            }
        }

        var summaryCustomizationPrice = ($('.configurator-summary-line.customization-price').length === 1 && !$('#add-to-cart').prop('disabled')) || $('.configurator-summary-line.customization-price').length > 1;
        if (optedIn && summaryCustomizationPrice) {
            ogOfferContainer.addClass('subscribed');
            $('.summary-sub-total').html($('.summary-sub-total').data('subscription'));
            $('.summary-total-price').html($('.summary-total-price').data('subscription'));
        } else {
            ogOfferContainer.removeClass('subscribed');
            $('.summary-sub-total').html($('.summary-sub-total').data('original'));
            $('.summary-total-price').html($('.summary-total-price').data('original'));
        }

        if (summaryCustomizationPrice) {
            $('.product-core-info .product-price').each(function () {
                var pricingSales = $(this).find('.price-sales');
                var pricingStandard = $(this).find('.price-standard');
                var subscriptionPricing = pricingSales.data('subscription-price');

                if (optedIn) {
                    // Update pricing to show subscription discount
                    if (subscriptionPricing && !pricingStandard.length) {
                        var subscriptionPrice = pricingSales.clone();
                        subscriptionPrice.find('.price-display').html(subscriptionPricing);
                        pricingSales.removeClass('price-sales').addClass('price-standard').after(subscriptionPrice);
                    }
                } else {
                    // Update pricing to remove subscription discount
                    if (subscriptionPricing && pricingStandard.length) {
                        pricingStandard.removeClass('price-standard').addClass('price-sales');
                        pricingSales.remove();
                    }
                }
            });
        }
    }
}

/**
 * @description Initialize Ordergroove
 */
function init() {
    if (window.OG) {
        // Add listener for subscribe option changes
        if (window.OG.addOptinChangedCallback) {
            window.OG.addOptinChangedCallback(function (optin) {
                updatePDP(optin.optedIn);
            });
        }
        // Set initial state of Add to Cart button
        if (window.OG.getOptins) {
            var optins = window.OG.getOptins();
            var optedIn = false;
            if (optins && optins.length) {
                var pid = $('#pid').val();
                optedIn = optins.some(function (item) {
                    return item.product === pid;
                });
                updatePDP(optedIn);
            }
            // Trigger OrderGroove-PostPurchaseTracking to make sure OG optins are synced with basket optins
            var subOptionSelected = $('#subOptionSelected').val();
            if (subOptionSelected && subOptionSelected !== 'false' && (optedIn || subOptionSelected !== 'null')) {
                subOptionSelected && updatePDP(true);
                $('.optin-btn og-optin-button').click();
                $('#pdpMain og-when[test="regularEligible"] og-optin-button').click();
            } else {
                $('.optout-btn og-optout-button').click();
                $('#pdpMain og-when[test="regularEligible"] og-optout-button').click();
            }
        }

        if ($('.configurator-number .configurator-option.selected .configurator-option-select').is(':disabled')) {
            $('.og-offer-custom').addClass('hide');

            if($('.add-to-cart').hasClass('disabled')) {
                $('.addToCartBtnText').show();
                $('.subscribeBtnText').hide();
            }
        }
        $(document).on('click', '.og-modal-trigger', function (e) {
            e.preventDefault();
            dialog.open({
                html: $('#og-asset').html(),
                options: {
                    dialogClass: 'notifyme-dialog og-modal',
                    open: function (e) {
                        e.preventDefault();
                        $('.ui-widget-overlay').addClass('notify-overlay');
                    }
                }
            });
        });
    }
}

/**
 * @description Clear optins on place order
 */
function clear() {
    if (window.OG) {
        window.OG.clear();
    }
}

module.exports = {
    init: init,
    clear: clear
};
