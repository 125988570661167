var util = require('../../../util');

module.exports = {
    init: function () {
        var $pdpMain = $('#pdpMain');

        $pdpMain.on('change', '#pdp-product-name', function () {
            window.location.href = $(this).val();
        });

        var scrollToSection = function (scrollPos) {
            $('html, body').animate({
                scrollTop: scrollPos
            }, 1000);
        };

        $pdpMain.on('click', '.p1-header a', function (e) {
            e.preventDefault();
            var scrollPos;
            if ($(this).hasClass('section-available')) {
                scrollPos = $(this).data('sectionTop') - $('.p1-header').height() - 31;
                scrollToSection(scrollPos);
            } else if ($(this).hasClass('customize-button')) {
                if (util.getViewport() > 991) {
                    scrollPos = $('.product-core-info').offset().top - 60;
                } else {
                    scrollPos = $('.product-core-info').offset().top;
                }
                scrollToSection(scrollPos);
            }
        });
    }
}
