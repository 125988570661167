'use strict';

var searchplaceholder = require('./searchplaceholder'),
	searchsuggest = require('./searchsuggest'),
	searchsuggestbeta = require('./searchsuggest-beta');

var headersearch = {
	init: function () {
		/**
		 * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
		 * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
		 * */
		var searchContainer = '.header-search',
			searchTrigger = '#mobileSearchShowBtn',
			searchClose = '#closeSearch';
		var toggleAriaExpanded = function (el) {
			var currValue = $(el).attr('aria-expanded');
			if (currValue == 'true') {
				currValue = 'false';
			} else {
				currValue = 'true';
			}
			$(el).attr('aria-expanded', currValue);
		}

		if (SitePreferences.LISTING_SEARCHSUGGEST_LEGACY) {
			searchsuggest.init($(searchContainer), Resources.SIMPLE_SEARCH);
		} else {
			searchsuggestbeta.init($(searchContainer), Resources.SIMPLE_SEARCH);
		}
		$(searchTrigger + ', ' + searchClose).on('click', function (e) {
			e.preventDefault();
			toggleAriaExpanded(this);
			$(searchContainer).toggle();
		});

		searchplaceholder.init();

		// Search Form submit empty field
		var $searchForm = $('form[name$="simpleSearch"]');
		$searchForm.on('submit', function () {
			var inputs = {};
			inputs = $(this).serializeArray();
			var formObj = {};
			$.each(inputs, function (i, input) {
				formObj[input.name] = input.value;
			});
			if (formObj.q === 'SEARCH' || formObj.q === '') {
				require('./dialog').open({
					html: '<p>Please Enter Search text</p>'
				});
				return false;
			}
		});
	}
};

module.exports = headersearch;
