var util = require('../../../util');

var initHeaderAnchors = function () {
    var $anchors = $('.header-anchor a');
    var $mobileAnchors = $('#anchors-selector option');
    $anchors.each(function () {
        var anchorTarget = $(this).attr('href');
        if ($(anchorTarget).length) {
            $(this).addClass('section-available');
            $(this).data('sectionTop', $(anchorTarget).offset().top).data('sectionBottom', $(anchorTarget).offset().top + $(anchorTarget).outerHeight(true));
            $mobileAnchors.filter('[value="' + anchorTarget + '"]').data('sectionTop', $(anchorTarget).offset().top).data('sectionBottom', $(anchorTarget).offset().top + $(anchorTarget).outerHeight(true));
        } else {
            $(this).addClass('section-unavailable');
            $mobileAnchors.filter('[value="' + anchorTarget + '"]').addClass('section-unavailable');
            if ($mobileAnchors.filter('[value="' + anchorTarget + '"]').hasClass('section-unavailable')) {
                $('#anchors-selector .section-unavailable').remove();
            }
        }
    });
};

var initAnchorScroll = function () {
    var $pdpMain = $('#pdpMain');

    $pdpMain.on('change', '#anchors-selector', function () {
        var scrollPos = $(this).find('option:selected').data('sectionTop') - 80;
        if ($('#anchors-selector :selected').text() == 'Compare') {
            scrollPos = scrollPos + 48;
        }
        var $this = $(this);
        $this.hide();
        setTimeout(function () {
            $this.fadeIn(200);
        }, 1000);
        util.scrollBrowser(scrollPos);
    });

    $(window).on('scroll', function () {
        var $p1Header = $('.p1-header');

        if (!$p1Header.length) {
            return false
        }

        var bottomPos = $(window).scrollTop(); // Poll the browser window for the amount user has scrolled down from the top
        var $productInfo = $('.pdp-main .pdpForm .product-add-to-cart'); // Updated the selector being used for the change point to the add to cart button container
        if ($('.disable-commerce').length) {
            $('.p1-header-right .pricing-section').addClass('no-comm');
            $productInfo = $('.disable-commerce .product-top');
        }
        if ($productInfo.length === 0) {
            $productInfo = $('#continue-shopping-oos');
        }
        var productInfoPos = $productInfo.offset().top;
        if (bottomPos >= productInfoPos) {
            $p1Header.addClass('sticky');
            $('.pdp-p1-module').addClass('sticky-child');
            initHeaderAnchors();
            $('nav.primary').addClass('hide-sticky');
        } else {
            $p1Header.removeClass('sticky');
            $('.pdp-p1-module').removeClass('sticky-child');
            $('nav.primary').removeClass('hide-sticky');
        }

        var headerBottom = $p1Header.offset().top + $p1Header.height() + 32;
        var $anchors = $('.header-anchors a:not(.section-unavailable)');
        var $mobileAnchors = $('#anchors-selector option:not(.section-unavailable)');

        if (util.getViewport() > 991) {
            $anchors.each(function () {
                var sectionTop = $(this).data('sectionTop');
                var sectionBottom = $(this).data('sectionBottom');
                if (headerBottom >= sectionTop && headerBottom < sectionBottom) {
                    $(this).closest('.header-anchor').addClass('active').siblings().removeClass('active');
                } else {
                    $(this).closest('.header-anchor').removeClass('active');
                }
            });
        } else {
            $mobileAnchors.each(function () {
                var sectionTop = $(this).data('sectionTop') - 34;
                if ($(window).scrollTop() >= sectionTop) {
                    $(this).prop('selected', true).attr('selected', 'selected').siblings().prop('selected', false).removeAttr('selected');
                }
            });
        }
    });

    $(window).on('resize', function () {
        initHeaderAnchors();
    });
}

module.exports = {
    initAnchorScroll: initAnchorScroll
}
