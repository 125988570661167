'use strict';

var dialog = require('../../dialog'),
	minicart = require('../../minicart'),
	bonusProductsView = require('../../bonus-products-view'),
	page = require('../../page'),
	util = require('../../util'),
	membersOnlyModal = require('./membersOnlyModal'),
	TPromise = require('promise'),
	_ = require('lodash');

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form) {
	var $form = $(form),
		$qty = $form.find('select[name="Quantity"]');
	if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
		$qty.val('1');
	}
	return TPromise.resolve($.ajax({
		type: 'POST',
		url: util.ajaxUrl(Urls.addProduct),
		data: $form.serialize()
	}));
};

/**
 * @description Make the AJAX request to add a MyJoys item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addMyJoysItemToCart = function (form) {
	var $form = $(form),
		$qty = $form.find('select[name="Quantity"]');
	if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
		$qty.val('1');
	}
	return TPromise.resolve($.ajax({
		type: 'POST',
		url: util.ajaxUrl(Urls.addMyJoysFromWishlist),
		data: $form.serialize()
	}));
};


/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
	var isContinueShoppingButton = $(e.target).attr('id') == 'continue-shopping-oos';
	if (isContinueShoppingButton) {
		return;
	}

	e.preventDefault();
	$('#addToCartCustomErrorMsg').empty();

	if ($('.optin-btn og-optin-button').is('[subscribed]') && $('#add-to-cart').val() === 'Update') {
		$('.optin-btn og-optin-button').trigger('click');
	}

	var $form = $(this).closest('form');
	if ($form.length === 0) {
		$form = $(this.form);
	}
	if ($(this).hasClass('product-subscribe-update')) {
		$.ajax({
			url: Urls.updateSubscribeProduct,
			type: 'POST',
			data: $form.serialize(),
			success: function (response) {
				window.location.href = response.location;
			},
			error: function (err) {
				console.log(err);
			}
		});
		return;
	}
	if ($(this).attr('showMembersOnlyForm') === 'true') {
		membersOnlyModal();
		return false;
	}

	if ($(this).hasClass('disabled') || $(this).is(':disabled') || $(this).attr('notSelectedVariant') != undefined) {
		var $unselectedVariants = $('.must-select:not(.selected)');
        var isTagCustomizer = $('.pdp-main.pdp-tag-customizer').length > 0;
        var isPersonalizeTabActive = $('#messages-tab.selected').length > 0;
		if ($unselectedVariants.length > 0) {
			$unselectedVariants.addClass('not-selected');
			if ($unselectedVariants.parents('.attribute').length > 0) {
				$unselectedVariants.parents('.attribute').addClass('error');
			}
			$('.selection-error').show();
			// adding margin for error
			for (var i = 0; i < $unselectedVariants.length; i++) {
				$($unselectedVariants[i]).parents('.custom-select-field').first().addClass('has-error');
			}
			$unselectedVariants.first().find('.header').focus();
		}

		if ($(this).is('#add-gc-to-cart')) {
			var $form = $(this).parents('form');
			$form.valid();
		}
        if (isTagCustomizer && isPersonalizeTabActive) {
            $('.add-personalization-option-label').addClass('error');
            $('.add-personalization-option-text').addClass('error');
            if ($('#messages-content.open .error-msg').children().length === 0) {
                $('.add-personalization-option-error-msg').removeClass('visually-hidden');
            }
        }
		return false;
	}

	if ($(this).hasClass('wedgeworks')) {
		window.location.href = $('input[name="customizeURL"]').val();
		return false;
	}

	addItemToCart($form).then(function (response) {
		var $uuid = $form.find('input[name="uuid"]');
		// for A/B testing
		if ($('.mobile-a2c-participant').length && util.getViewport() < 768) {
			mobileAddToCartIntercept();
			return;
		}
        if (response && response.isNotValidAddingToCart) {
            $('#addToCartCustomErrorMsg').text(response.errorMessage);
            return;
        }
		if (window.location.href.indexOf('subscription') > -1) {
			page.redirect(Urls.MSI);
		} else if ($uuid.length > 0 && $uuid.val().length > 0 || window.pageContext.ns === 'cart' || window.pageContext.ns === 'checkout' || window.pageContext.ns === 'configurator' || window.pageContext.ns === 'customizer') {
            if ($('.configurator.tag-customizer').length > 0) {
                if ($('input#cartAction').length > 0) {
                    page.redirect(Urls.cartShow);
                } else {
                    if (response.indexOf('max-quantity-error') >= 0) {
                        dialog.open({
                            url: Urls.maxQuantityError
                        });
                    } else {
                        minicart.show(response);
                    }
                }
            } else {
                page.redirect(Urls.cartShow);
            }
		} else if (window.pageContext.ns === 'surefitdetails') {
			page.redirect(response.redirect);
		} else {
			// do not close quickview if adding individual item that is part of product set
			// @TODO should notify the user some other way that the add action has completed successfully
			if (!$(this).hasClass('sub-product-item')) {
				dialog.close();
			}
			if (response.indexOf('max-quantity-error') >= 0) {
				dialog.open({
					url: Urls.maxQuantityError
				});
			} else {
				minicart.show(response);
			}
		}
	}.bind(this));
};

/**
 * @description Open mobile cart intercept dialog
 */
var mobileAddToCartIntercept = function () {
	dialog.open({
		url: Urls.mobileAddToCartIntercept,
		options: {
			dialogClass: 'cart-intercept',
			open: function () {
				$('.mobile-a2c-intercept-continue').on('click', function () {
					dialog.close();
				});
				$('.mobile-a2c-intercept-cart').on('click', function () {
					page.redirect(Urls.cartShow);
				});
			}
		}
	});
};

/**
 * @description Handler to handle the add to cart event
 */
var addMyJoysToCart = function (e) {
	e.preventDefault();
	var $form = $(this).closest('form');
	if ($form.length === 0) {
		$form = $(this.form);
	}
	addMyJoysItemToCart($form).then(function (response) {
		var $uuid = $form.find('input[name="uuid"]');
		if ($uuid.length > 0 && $uuid.val().length > 0 || util.getViewport() < 768) {
			if ($('body.enable-slideout-cart').length === 0) {
				page.redirect(Urls.cartShow);
			}
		} else {
			// do not close quickview if adding individual item that is part of product set
			// @TODO should notify the user some other way that the add action has completed successfully
			if (!$(this).hasClass('sub-product-item')) {
				dialog.close();
			}
			minicart.show(response);
		}
	}.bind(this));
};


/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
	e.preventDefault();
	var $productForms = $('#product-set-list').find('form').toArray();
	TPromise.all(_.map($productForms, addItemToCart))
		.then(function (responses) {
			dialog.close();
			if (util.getViewport() < 768 && $('body.enable-slideout-cart').length === 0) {
				page.redirect(Urls.cartShow);
			} else {
				// show the final response only, which would include all the other items
				minicart.show(responses[responses.length - 1]);
			}
		});
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 * @param {Element} target The target on which an add to cart event-handler will be set
 */
module.exports = function (target) {
	$('.add-to-cart[disabled], .add-to-cart.disabled').attr('title', $('.availability-msg').text());

	if (target) {
		target.off('click');
		target.on('click', '.add-to-cart', addToCart);
		target.on('click', '.add-myjoys-to-cart', addMyJoysToCart);
	} else {
		$('.add-to-cart').on('click', addToCart);
		$('.add-myjoys-to-cart').on('click', addMyJoysToCart);
	}

	$('#add-all-to-cart').off('click').on('click', addAllToCart);
};
