'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    customize = require('./customize'),
    availability = require('./availability'),
    image = require('./image'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    recommendations = require('./recommendations'),
    membersOnlyModal = require('./membersOnlyModal'),
    membersOnlyModalAnother = require('./membersOnlyModalAnother'),
    ordergroove = require('./custom/ordergroove'),
    variant = require('./variant'),
    anchorScroll = require('./custom/anchorScroll'),
    p1Content = require('./custom/p1Content'),
    stickyprod = require('./stickyprod'),
    productTile = require('../../product-tile'),
	checkMembersOnlyStatus = require('./custom/checkMembersOnlyStatus'),
	imagesLoaded = require('imagesloaded');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom () {
    productNav();
    recommendations();
    tooltip.init();
	checkMembersOnlyStatus.checkMembersOnlyStatus();
    if (window.showInitMembersOnlyModal && !$('.intersitial-message-wrapper').length) {
        membersOnlyModal();
    }

    membersOnlyModalAnother();
}

function getAcnchorElementPoition(hash) {
	var deviceSize;
	if (window.innerWidth < 992) {
		deviceSize = 10;
	}
	if (window.innerWidth > 991) {
		deviceSize = 87;
	}
	var scrollPosition = $(hash).offset().top - deviceSize;
	return scrollPosition;
}

function scrollOnPageLoad () {
	var hashLink = window.location.hash;
	if (hashLink.indexOf('&') === 0 && hashLink.indexOf('=') === 0) {
		if ($(hashLink).length) {
			$('html, body').animate({scrollTop: getAcnchorElementPoition(window.location.hash)}, 0);
		}
	}
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents () {
    var $pdpMain = $('#pdpMain');

    if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
		setTimeout(scroll(0, 0), 1);
    }
    addToCart();
    customize();
    availability();
    variant();
    image();
    productSet();
    p1Content.init();
    anchorScroll.initAnchorScroll();

	if (window.showInitMembersOnlyModal) {
		$(document).on('close.interstitial', function () {
			membersOnlyModal();
		});
	}

    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    if ($('.giftcard-pdp').length) {
        var giftInfo = $('.giftcard-top-content .product-section-inner').html();
        $('.giftcard-pdp .mobile-product-info .product-main-info').html(giftInfo);
    }

    if ($('.disable-commerce').length) {
        $('.mobile-product-info').addClass('no-comm');
    }

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $('#giftcardDeliveryDate').on('change', function () {
        var iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
        if (!iOS) {
            $('#giftcardDeliveryDate').blur();
        }
    });

    if ($('#giftcardDeliveryDate').length) {
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var maxMonth = dtToday.getMonth() + 4;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (maxMonth < 10)
            maxMonth = '0' + maxMonth.toString();
        if (day < 10)
            day = '0' + day.toString();
        var maxDate = year + '-' + maxMonth + '-' + day;
        var minDate = year + '-' + month + '-' + day;
        $('#giftcardDeliveryDate').attr({
            'min': minDate,
            'max': maxDate
        });
    }

    // toggle gift card delivery date UI
    $pdpMain.on('click', '#giftSendLater', function () {
        if (!$('.gift-card-data .later-date').length) {
            $('.expected-date').addClass('later-date');
            $('#giftcardDeliveryDate').addClass('required');
            $('#add-gc-to-cart').addClass('disabled');
        }
    });

    // toggle gift card delivery date UI
    $pdpMain.on('click', '#giftSendNow', function () {
        $('#giftcardDeliveryDate').val('');
        if ($('.gift-card-data .required.valid').not('#giftcardDeliveryDate').length === $('.gift-card-data .required').not('#giftcardDeliveryDate').length) {
            $('#add-gc-to-cart').removeClass('disabled');
        }
        if ($('.gift-card-data .later-date').length) {
            $('.expected-date').removeClass('later-date');
            $('#giftcardDeliveryDate').removeClass('required');
            $('.datepicker-overlay').show();
        }
    });

    // toggle gift card delivery date UI
    $pdpMain.on('click touchstart', '.datepicker-overlay', function () {
        $('.datepicker-overlay').hide();
        $('#giftcardDeliveryDate').click();
    });

    // toggle gift card delivery date UI
    $pdpMain.on('click touchstart', '#giftcardDeliveryDate', function () {
        $('.datepicker-overlay').hide();
    });

    $pdpMain.on('click', '.pr-snippet-stars, .pr-snippet-review-count', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('.reviews-container').offset().top
        }, 1000);
    });
    $pdpMain.on('click', '#egcShowExamples', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href'),
			options: {
				dialogClass: 'egc-dialog',
				width: 652
			}
		});
	});
    $pdpMain.on('click', '.js-show-colors', function (e) {
        e.preventDefault();
        var swatches = $(this).parent().find('.selectable');
        var hiddenSwatches = $(this).parent().find('.selectable.hide');
        var buttonText = '';
        if (hiddenSwatches.length) {
            hiddenSwatches.removeClass('hide');
            buttonText = $(this).data('text-less');
            $(this).text(buttonText);
        } else {
            var i = 0;
            swatches.each(function () {
                if (i > 7) {
                    $(this).addClass('hide');
                }
                i++;
            });
            buttonText = $(this).data('text-more');
            $(this).text(buttonText);
        }
    });
    $pdpMain.on('click', '#continue-shopping-oos', function (e) {
        e.preventDefault()
        var categoryLink = $('.breadcrumb:first li:last .breadcrumb-element');
        window.location.href = categoryLink.attr('href');
    });

    $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                width: 768
            }
        });
    });
    $('#free-returns').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });
    $('.pdpLeftCarousel ul').slick();
    $('.pdpPlayers ul').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    });

    $(document).on('click', '.p1-header .customize-button', function (e) {
        if (!$('#club-pdp-main').hasClass('.club-pdp-main')) {
            e.preventDefault();

            var position = $('.product-core-info').length > 0 ? $('.product-core-info').offset().top : 0;
            util.scrollBrowser(position);
        }
    });
    stickyprod.init();
	imagesLoaded('#pdpMain').on('always', function () {
		setTimeout(function () {
			$('html').removeClass('freeze-scrolling');
			$('.overlay-layer').remove();
			scrollOnPageLoad();
		}, 2500);
	});

    ordergroove.init();
}

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
        productTile.swatchImageCkick();
        productTile.productTileHover();
    }
};

module.exports = product;
