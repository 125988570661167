function init() {
    $('.js-profile-heading-toggle').on('click', function () {
        $(this).parents('.js-profile-box').toggleClass('expand');
    });
}

var headingToggleButton = {
    init: init
}

module.exports = headingToggleButton;