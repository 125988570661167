'use strict';

/**
 * A function to call the OrderGroove-PurchasePostTracking endpoint with the current OG optins.
 */
function onOptinChanged(subscptionOptions, optionChange) {
    var subOptionSelected = $('#subOptionSelected').val();
    if (subOptionSelected && subOptionSelected !== 'null' && subOptionSelected !== 'false') {
        var shadowRootSelect = $('#pdpMain og-when[test="regularEligible"] og-select-frequency')[0].shadowRoot.querySelector('og-select').shadowRoot.querySelector('select');
        shadowRootSelect.value = subOptionSelected;
        $('#subOptionSelected').val(null);
        var event = new Event('change', { 'bubbles': true, 'cancelable': true });
        shadowRootSelect.dispatchEvent(event);
    }
    var trackingData = window.OG.getOptins();
    if (subscptionOptions.optedIn) {
        var notPdpPage = $('#pdpMain').length ? '' : 'td ';
        var frequency = $(notPdpPage + 'og-offer[product="' + subscptionOptions.productId + '"] og-select-frequency')[0].shadowRoot.querySelector('og-select').shadowRoot.querySelector('select').value;
        if (JSON.stringify(trackingData).indexOf('"' + subscptionOptions.productId + '"') === -1) {
            var newSub = Object.assign({}, trackingData[0]);
            newSub.product = subscptionOptions.productId;
            newSub.tracking_override.every = parseInt(frequency[0]);
            newSub.tracking_override.every_period = parseInt(frequency[2]);
            trackingData.push(newSub);
        } else {
            for (var i = 0; i < trackingData.length; i++) {
                var option = trackingData[i];
                if (option.product === subscptionOptions.productId) {
                    option.tracking_override.every = parseInt(frequency[0]);
                    option.tracking_override.every_period = parseInt(frequency[2]);
                }
            }
        }
    }
    var data = {
        tracking: JSON.stringify(trackingData)
    };

    $.ajax({
        url: window.OrdergrooveTrackingUrl,
        method: 'POST',
        data: data,
        success: function () {
            if ($('div.mini-bag-content.cart-show').length) {
                $(document).trigger('updateMiniCart');
            }
            if (window.pageContext && window.pageContext.ns === 'cart' && !$('div.edit-product-modal:visible').length && optionChange) {
                window.location.replace(window.location.href);
            }
            // We want to trigger a quantity change event even though the quantity isn't changing
            // so that we execute the same logic that refreshes discounts when the quantity changes
            $('.quantity-form > .quantity').trigger('change');

            if ($('.optin-btn og-optin-button').is(':visible') && ['cart', 'update', 'subscription'].indexOf($('.add-to-cart').data('action')) === -1) {
                var optins = window.OG.getOptins();
                var optedIn = false;
                if (optins && optins.length) {
                    var pid = $('#pdpMain .og-offer-custom og-offer').attr('product');
                    optedIn = optins.some(function (item) {
                        return item.product === pid;
                    });
                    $('.subscribeBtnText').toggle(optedIn);
                    $('.addToCartBtnText').toggle(!optedIn);
                    if ($('.edit-product-modal:visible').length || $('#add-to-cart').attr('data-action') == 'update') {
                        $('.subscribeBtnText, .addToCartBtnText').toggle(false);
                        $('.updateBtnText').toggle(true);
                    }
                }
            }
        }
    });
}

function initOG() {
    if (window.OG) {
        // Set initial state of Add to Cart button
        if (window.backendOGOptions && window.OG.getOptins) {
            $('.cart-row').each(function () {
                var ogContainer = $(this).find('.og-cart-container')
                var pid = ogContainer.find('input[name="pliProductID"]').val();
                var optins = window.backendOGOptions;
                var optedIn = false;
                if (optins && optins.length) {
                    optedIn = optins.some(function (item) {
                        if (item.product === pid) {
                            var frequencyElement = ogContainer.find('og-when[test="regularEligible"] og-select-frequency');
                            if (frequencyElement.length) {
                                var newValue = item.tracking_override.every + '_' + item.tracking_override.every_period;
                                var shadowRootSelect = frequencyElement[0].shadowRoot.querySelector('og-select').shadowRoot.querySelector('select');
                                shadowRootSelect.value = newValue;
                                ogContainer.find('og-offer').attr('frequency', newValue);
                                var event = new Event('change', { 'bubbles': true, 'cancelable': true });
                                shadowRootSelect.dispatchEvent(event);
                            }
                        }
                        return item.product === pid;
                    });
                }
                // Trigger OrderGroove-PostPurchaseTracking to make sure OG optins are synced with basket optins
                if (optedIn) {
                    $(this).find('og-when[test="regularEligible"] og-optin-button').click();
                } else {
                    $(this).find('og-when[test="regularEligible"] og-optout-button').click();
                }
            });
            $('div.og-regular-offer-content og-optout-button, div.og-regular-offer-content og-optin-button').on('click change', function (el) {
                onOptinChanged(el, true);
            });
        }
    }
}

module.exports = function () {
    initOG();
    if (!window.OrdergrooveLegacyOffers && window.OG && window.OG.addOptinChangedCallback) {
        window.OG.addOptinChangedCallback(onOptinChanged);
    }
};
