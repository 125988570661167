module.exports = {
    init: function () {
        var $banner = $('.js-global-notification-banner');
        var $slides = $banner.find('.js-global-notification-banner__slide');
        var options = {
            dots: false,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };

        if ($banner.length && $slides.length > 1) {
            $banner.slick(options);
        }
    }
}