'use strict';

require('../../touch')(); //touch events

var dialog = require('../../dialog'),
    util = require('../../util'),
    imageSliders = require('./custom/imageSliders');

var zoomMediaQuery = matchMedia('(min-width: 960px)');

/**
 * @description Enables the zoom viewer on the product detail page
 * @param zmq {Media Query List}
 */
var loadZoom = function (zmq) {
    var $imgZoom = $('#pdpMain .main-image.slick-current'),
        hiresUrl;
    if (!zmq) {
        zmq = zoomMediaQuery;
    }
    if ($imgZoom.length === 0 || dialog.isActive() || util.isMobile() || !zmq.matches) {
        // remove zoom
        $imgZoom.trigger('zoom.destroy');
        return;
    }
    hiresUrl = $imgZoom.attr('href');

    if (hiresUrl && hiresUrl !== 'null' && hiresUrl.indexOf('noimagelarge') === -1 && zmq.matches) {
        $imgZoom.zoom({
            url: hiresUrl
        });
    }
};

zoomMediaQuery.addListener(loadZoom);


/**
 * @description Smart Zoom functionality for mobile slider
 */
var eventSmartZoom = function () {
    // Add Event to 'Zoom' button
    $('.pdp-mobile-slider-zoom-btn').on('click', function (e) {
        e.stopPropagation();
        var $zoomWrapper = $('.mobile-zoom-wrapper');
        if (!$zoomWrapper.length) {
            createSmartZoom();
            increaseSmartZoom();
            $(this).addClass('visually-hidden');
        } else {
            destroySmartZoom();
        }
    });

    $('.product-primary-image-mobile .pdp-mobile-slider').doubleTap(function () {
            var $zoomWrapper = $('.mobile-zoom-wrapper');

            if (!$zoomWrapper.length) {
                createSmartZoom();
                increaseSmartZoom();
                $('.pdp-mobile-slider-zoom-btn').addClass('visually-hidden');
            }
        }
    );

    // Activate 'close zoom' button
    $('.smart-zoom-close-btn').on('click', function (e) {
        e.stopPropagation();
        var $zoomWrapper = $('.mobile-zoom-wrapper');

        if ($zoomWrapper.length) {
            $('.pdp-mobile-slider-zoom-btn').removeClass('visually-hidden');
            destroySmartZoom();
        }
    });
    // Destroy Smart Zoom on resize
    $(window).on('orientationchange resize', function () {
        destroySmartZoom();
    });
};

/**
 * @description Create Smart Zoom functionality for mobile slider
 */
var createSmartZoom = function () {
    // if doesn't exist
    if (!$('.pdp-mobile-slider').length) {return;}
    // if it still has 'data-lazy' attribute and doesn't has 'src'
    if ($('.pdp-mobile-slider .slick-active img').attr('src') === undefined) {return;}
    // if it is still appearing in slider
    if ($('.pdp-mobile-slider .slick-active img').css('opacity') != 1) {return;}

    var $activeImage = $('.pdp-mobile-slider .slick-active img').clone();

    $activeImage.addClass('mobile-zoom-image');
    $('.pdp-mobile-slider').append($activeImage);

    // show 'close zoom' button
    $('.smart-zoom-close-btn').addClass('active');

    //create smart zoom
    $activeImage.smartZoom({
        'maxScale': 2.5,
        'containerClass': 'mobile-zoom-wrapper'
    });
};

/**
 * @description Destroy Smart Zoom on mobile slider
 */
var destroySmartZoom = function () {
    if (!$('.mobile-zoom-wrapper').length) {return;}

    var $zoomWrapper = $('.mobile-zoom-wrapper');
    var $activeImage = $zoomWrapper.find('img');

    // hide 'close zoom' button
    $('.smart-zoom-close-btn').removeClass('active');

    $zoomWrapper.fadeOut('500', function () {
        $zoomWrapper.find('img').smartZoom('destroy');
        $activeImage.remove();
    });
};

/**
 * @description Increase Size for Smart Zoom on mobile slider
 */
var increaseSmartZoom = function () {
    var $activeImage = $('.mobile-zoom-wrapper img');
    $activeImage.smartZoom('zoom', 2.5);
};

/**
 * @description Decrease Size for Smart Zoom on mobile slider
 */
var decreaseSmartZoom = function () {
    var $activeImage = $('.mobile-zoom-wrapper img');
    $activeImage.smartZoom('zoom', -2.5);
};

/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
var setMainImage = function (atts) {
    $('#pdpMain .plus-image-link').attr({
        href: atts.url,
        title: atts.title
    });
    $('#pdpMain .slick-current .primary-image').attr({
        src: atts.url,
        alt: atts.alt,
        title: atts.title
    });
    if (!dialog.isActive() && !util.isMobile()) {
        $('#pdpMain .main-image.slick-current').attr('href', atts.hires);
    }
    loadZoom();
};

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
var replaceImages = function () {
    var $newImages = $('#update-images'),
        $imageContainer = $('#pdpMain .product-image-container');
    if ($newImages.length === 0) { return; }

    $imageContainer.html($newImages.html());
    $newImages.remove();
    loadZoom();
};

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function () {
    imageSliders.setupCarousel();
    imageSliders.setupRecCarousel();

    if (dialog.isActive() || util.isMobile()) {
        $('#pdpMain .main-image').removeAttr('href');
    }
    loadZoom();
    // handle product thumbnail click event
    $('#pdpMain').on('click', '.productthumbnail', function (e) {
        e.preventDefault();
        // switch indicator
        $(this).closest('.product-thumbnails').find('.thumb.selected').removeClass('selected');
        $(this).closest('.thumb').addClass('selected');

        if ($(this).parents('.thumb').hasClass('thumb-360')) {
            $('#pdpMain .main-image').addClass('hidden');
            $('#pdpMain .spritespin').removeClass('hidden');
            var imageSize = $('.product-image-container').width() * .8;
            loadSprite360($('#thumbnails').data('sprite'), imageSize);

            $(window).resize(function () {
                imageSize = $('.product-image-container').width() * .8;
                loadSprite360($(this).parent().data('sprite'), imageSize);
            });
        } else {
            $('#pdpMain .main-image').removeClass('hidden');
            $('#pdpMain .spritespin').addClass('hidden');

            setMainImage($(this).data('lgimg'));
        }
    });

    //prevent finger scroll for swipeup/down
    $('#pdpMain').on('touchstart, click', '.product-primary-image a:not(.plus-image-link, #design-your-own-ball)', function (e) {e.preventDefault();});

    if($('#js-zoom-content').length > 0) {
       $('#pdpMain').addClass('zoom-enabled');
    }

    $('#pdpMain.zoom-enabled:not(.club-pdp-main)').on('click', '.product-primary-image', function (e) {
        e.preventDefault();
        if (e.target.classList.contains('slick-arrow') || e.target.classList.contains('design-your-own-ball')) {
            return;
        }

        var $zoomContent = $('#js-zoom-content');
        $('body').addClass('fixedPosition');
        $zoomContent.dialog({
            dialogClass: 'zoom-image-dialog',
            modal: true,
            draggable: false,
            resizable: false,
            width: $(window).width(),
            height: $(window).height(),
            left: 0,
            top: 0,
            open: function () {
                $('#js-zoom-content').removeClass('hidden');
            },
            close: function () {
                $('#js-zoom-content').addClass('hidden');
                $(this).dialog('destroy');
                $('body').removeClass('fixedPosition');
            }
        });
    });
};

module.exports.loadZoom = loadZoom;
module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
