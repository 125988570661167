'use strict';

var util = require('./util'),
	bonusProductsView = require('./bonus-products-view');

var timer = {
	id: null,
	clear: function () {
		if (this.id) {
			window.clearTimeout(this.id);
			delete this.id;
		}
	},
	start: function (duration, callback) {
		this.id = setTimeout(callback, duration);
	}
};

function closeMini() {
	$('.mini-bag-content, .mini-bag-content-overlay, .mobile-mini-bag-flyout').removeClass('cart-show');
    $('.primary.desktop, .primary.mobile').removeClass('is-fixed-layout');
    $(document).trigger('unfreezeBackground');
}

var minicart = {
	init: function () {
		this.$el = $('.mini-bag:visible');
		this.$content = this.$el.find('.mini-bag-content');

		$('.mini-bag-product').eq(0).find('.mini-bag-toggle').addClass('fa-caret-down');

		var suppressSlide  = false;
        var miniTotesHeightDesk = $('nav.desktop .mini-bag-totals').outerHeight() + $('nav.desktop .mini-cart-promo').outerHeight() + $('nav.desktop .mini-bag-header').outerHeight();
        var miniTotesHeightMob = $('nav.mobile .mini-bag-totals').outerHeight() + $('nav.mobile .mini-cart-promo').outerHeight() + $('nav.mobile .mini-bag-header').outerHeight();
		var bagCount = $('nav.primary.desktop .mini-bag-count-text').text();
        var mobHdrHeight = $('nav.mobile .nav-animate-container').outerHeight()
        var miniHeight = $(window).innerHeight() - mobHdrHeight;
		var hoverTimer;

		if ($('#wrapper').hasClass('pt_cart') || $('#wrapper').hasClass('pt_checkout')) {
            suppressSlide = true;
        }

        if (util.getViewport() > 768) {
            $('.mini-bag-products').css({height: 'calc(100% - ' + miniTotesHeightDesk + 'px)'});
        } else {
            $('.mini-bag-products').css({height: 'calc(100% - ' + miniTotesHeightMob + 'px)'});
            $('.mini-bag-content').css({height: + miniHeight});
        }

        $('.mini-bag-attributes').each(function () {
            if ($(this).height() > 57) {
                $(this).addClass('collapse');
            }
        });

		if (bagCount.length) {
			$('#mobile-minicount .mini-bag-count-text').text(bagCount);
			$('#mobile-minicount').removeClass('hide');
			$('.mini-cart-link').removeClass('mini-cart-empty');
		} else {
			$('#mobile-minicount').addClass('hide');
			$('.mini-cart-link').addClass('mini-cart-empty');
		}

        $('.mini-bag-pricing').each(function () {
            if ($(this).find('.price-sales').length && $(this).find('.price-standard').length) {
                $(this).addClass('sales-strike');
            }
        });

		$('.icon-shopping-bag').on('mousedown', function (e) {
			var url =  $(this).data('url');
			window.location.href = url;
		});

		$('.toggle-mini-line').on('click', function () {
            $(this).parents('.mini-bag-attributes').toggleClass('opened');
		});

		$('.minibag-btn-empty, #mobile-search, #mobile-nav-button, .center-content, .account-toggle').on('click', function (e) {
            if ($('.mini-bag-content.cart-show').length) {
                $('.mini-bag-close').trigger('click');
            }
		});

		// events
		this.$el.find('.mini-bag-toggle').on('mouseenter touchstart', function () {
			var that = this;
			hoverTimer = setTimeout(function () {
				if (!$('#wrapper.pt_cart, #wrapper.pt_checkout').length && util.getViewport() > 768 && that.$content.not(':visible')) {
					$('.mini-bag-content, .mini-bag-content-overlay, .mobile-mini-bag-flyout').addClass('cart-show');
					$(document).trigger('freezeBackground');
					$('.primary.desktop, .primary.mobile').addClass('is-fixed-layout');
				}
			}.bind(this), 1000);
		}.bind(this));

		this.$el.find('.mini-bag-toggle').on('mouseleave touchend', function () {
			clearTimeout(hoverTimer);
		});

		$('.page-links > li > a, .right-content .nav-item:not(.mini-cart-link)').on('mouseenter', function () {
            closeMini();
		});

		$('.hover-off').on('mouseenter', function () {
			if ($('.mini-bag-content.cart-show').length) {
                closeMini();
            }
		});

		$('.mini-bag-content-overlay, .mini-bag-close').on('click touchstart', function () {
			closeMini();
		});

		$('#mini-bag').on('mouseleave', function () {
			closeMini();
		});
	},
	updateMiniCart: function (params) {
		$(document).on('updateMiniCart', function (e) {
			$.ajax({
				url: Urls.updateMiniCart,
				type: 'POST',
				data: {},
				success: function (response) {
					if ($('.mini-bag-content').hasClass('cart-show')) {
						var response = response.replace("mini-bag-content", "mini-bag-content cart-show").replace("mini-bag-content-overlay", "mini-bag-content-overlay cart-show");
					}
					minicart.show(response);
				},
				error: function (err) {
					minicart.close();
				}
			});
		});
	},
	/**
	 * @function
	 * @description Shows the given content in the mini cart
	 * @param {String} A HTML string with the content which will be shown
	 */
	show: function (html, needSlide) {
		this.$el.html(html);
        util.scrollBrowser(0);
		this.init();
		if (needSlide || typeof needSlide == 'undefined') {
			this.slide();
            bonusProductsView.loadBonusOption();
		}
	},
	/**
	 * @function
	 * @description Slides down and show the contents of the mini cart
	 */
	slide: function () {
		setTimeout(function () {
            $('.mini-bag-content, .mini-bag-content-overlay, .mobile-mini-bag-flyout').addClass('cart-show');
            $('.primary.desktop, .primary.mobile').addClass('is-fixed-layout');
            $(document).trigger('freezeBackground');
        }, 300);
	},
	/**
	 * @function
	 * @description Closes the mini cart with given delay
	 * @param {Number} delay The delay in milliseconds
	 */
	close: function (delay) {
		if ($('body.enable-slideout-cart').length === 0) {
			timer.clear();
			this.$content.slideUp(delay);
		}
	}
};

module.exports = minicart;
