'use strict';
var dialog = require('../dialog');

var accountt = {
    init: function () {
        $('.flex-details').on('click', '.order-title, .arrow-tog', function () {
            $(this).closest('.flex-details').toggleClass('opened');
        });
        $('#password-reset').on('click', function (e) {
            e.preventDefault();
            dialog.open({
                url: $(e.target).attr('href'),
                position: {my: "center", at: "center", of: window},
                options: {
                    dialogClass: 'password-reset-dialog',
                    width: 485,
                    open: function () {
                        validator.init();
                        var $requestPasswordForm = $('[name$="_requestpassword"]'),
                            $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
                        $($submit).on('click', function (e) {
                            if (!$requestPasswordForm.valid()) {
                                return;
                            }
                            e.preventDefault();
                            dialog.submit($submit.attr('name'));
                        });
                    }
                }
            });
        });
    }
};

module.exports = accountt;