'use strict';

require('../../touch')(); //touch events

var dialog = require('../../dialog'),
    imagesLoaded = require('imagesloaded'),
    util = require('../../util');

var startOnSlide = 0;
var updateImagesQueue = [];
var logoSelector = '.ball-logo';

var init = function (callback) {
    var imgLoad = imagesLoaded($('.configurator-main-image'));
    imgLoad.on('fail', function (imgs) {
        replaceBrokenImages(imgs);
    });
    imgLoad.on('always', function () {
        //init imagery
        initCaro(startOnSlide);
    });
};

var initCaro = function (initialSlide, callback) {
    if ($('.configurator-main-image').hasClass('slick-initialized')) {
        $('.configurator-main-image').slick('unslick');
    }

    $('.configurator-main-image').on('init', function (e, slick) {
        if (typeof callback === 'function') {
            callback(e, slick);
        }
    });
    $('.configurator-main-image').slick({
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        swipe: true,
        touchMove: true,
        initialSlide: initialSlide,
        waitForAnimate: false,
        dotsClass: 'configurator-product-thumbnails',
        customPaging: function (slider, i) {
            var thumb = $(slider.$slides[i]).data('thumb');
            if ($('.configurator.tag-customizer').length > 0) {
                var currentImage = slider.$slides[i].innerHTML;
                if (currentImage.includes('noimage')) {
                    thumb = $('.configurator-main-image').data('brokenimgurlsmall');
                }
            }
            return '<div class="thumbnail-link"><img class="productthumbnail" src=' + thumb + '></a>';
        }
    });
    $('.configurator-main-image').on('afterChange', function (e, slick, index) {
        startOnSlide = index; //remember where the user last left off
    });
};

var gotoSlide = function (slideName) {
    if (typeof slideName === 'number') {
        // if a number was passed in, go to that number index in the carousel
        $('.configurator-main-image').slick('slickGoTo', slideName);
    } else if (typeof slideName === 'string' && window.CustomizerResources.PREF_SLIDE_NAMES.indexOf(slideName) > -1) {
        //if string, set slide index for a given template name
        $('.configurator-main-image').slick('slickGoTo', window.CustomizerResources.PREF_SLIDE_NAMES.indexOf(slideName));
    }
};

var replaceAndInit = function (newImages, slideName) {
    if (!!newImages || newImages.length > 0) {

        //if we are currently in the middle of an image refresh, queue this update
        if ($('.configurator-main-image').hasClass('loading-indicator')) {
            updateImagesQueue = newImages;
            return;
        }

        //show loading indicator
        $('.configurator-main-image').addClass('loading-indicator');

        //set up a temporary, hidden div, inject all Scene7 images, and allow them fully load before swapping out carousel images.
        var $preloadDiv = $('#preload-scene7-images').length ? $('#preload-scene7-images') : $('<div/>').attr('id', 'preload-scene7-images').appendTo(document.body);
        var width = $('.configurator-main-image').find('img.primary-image').width();
        var height = false;
        if ($('.configurator.tag-customizer').length > 0) {
            width = window.CustomizerResources.SCENE7_IMAGE_WIDTH;
            height = window.CustomizerResources.SCENE7_IMAGE_HEIGHT;
        }
        if (!width || width === 0) {
            width = 840;
        }
        for (var i = 0; i < newImages.length; i++) {
            //set the carousel image width, compensating for hi-res screens
            var src = util.appendParamToURL(newImages[i], 'wid', width);
            if ($('.configurator.tag-customizer').length > 0 && height) {
                var src = util.appendParamToURL(src, 'hei', height);
            }
            //set the zoom image size
            var hires = util.appendParamToURL(newImages[i], 'wid', 1400);
            var thumbUrl = util.appendParamToURL(newImages[i], 'wid', 150);
            var title = !!window.CustomizerResources.PREF_SLIDE_NAMES && !!window.CustomizerResources.PREF_SLIDE_NAMES[i] ? window.CustomizerResources.PREF_SLIDE_NAMES[i] : '';
            var tag = '<a href="' + hires + '" target="_blank" class="product-image main-image" data-thumb="' + thumbUrl + '" title="' + title + '"><img class="primary-image" src="' + src + '" title="' + title + '"/></a>';
            $preloadDiv.append(tag);
        }
        /* if we need to digest an associative array, use the following code
        for (var imgType in newImages) {
            var tag = '<a href="' + newImages[imgType] + '" target="_blank" class="product-image main-image" title="' + imgType + '"><img class="primary-image" src="' + newImages[imgType] + '"/></a>';
            $('.configurator-main-image').append(tag);
        }
        */
        var imgLoad = imagesLoaded($preloadDiv);
        imgLoad.on('always', function () {

            //replace all images and re-init slick carousel
            if ($('.configurator-main-image').hasClass('slick-initialized')) {
                $('.configurator-main-image').slick('unslick');
            }
            $('.configurator-main-image').html($preloadDiv.find('a').remove());

            //set startOnSlide to the index a given template name
            if (!!slideName && window.CustomizerResources.PREF_SLIDE_NAMES.indexOf(slideName) > -1) {
                startOnSlide = window.CustomizerResources.PREF_SLIDE_NAMES.indexOf(slideName);
            }

            //re-initialize the carousel and zoom functionality
            init();

            //remove loading indicator
            $('.configurator-main-image').removeClass('loading-indicator');

            //if we had an image update queued, execute this now
            if (updateImagesQueue.length) {
                var newImages = updateImagesQueue;
                updateImagesQueue = []; //reset queue
                replaceAndInit(newImages);
            }
            return;
        });
        imgLoad.on('fail', function (imgs) {
            replaceBrokenImages(imgs);
        });
    }
};

var replaceBrokenImages = function (imgs) {
    if (!imgs) {
        return;
    }
    var $errortxt = $('.configurator-controls').find('.config-tab-content.open .error-msg').html('');
    for (var i = 0; i < imgs.images.length; i++) {
        var img = imgs.images[i];
        if (!!img && !img.isLoaded) {
            $(img.img).attr('src', $('.configurator-main-image').data('brokenimgurl'));
            var $error = $('<p></p>').html('Error loading preview image for <em>' + $(img.img).attr('title') + '</em> face');
            $errortxt.append($error);
        }
    }
};

var replaceLogo = function (selection) {
    if (!!selection && $(selection).length) {
        var $newlogoimg = $(selection).siblings('.config-option-label').find(logoSelector);
        var $pagelogo = $('.configurator-productname').find(logoSelector);
        if ($newlogoimg.length && $pagelogo.length) {
            $pagelogo.replaceWith($newlogoimg);
        }
    }
};

module.exports = {
    init: init,
    replaceCaroImages: replaceAndInit,
    gotoSlide: gotoSlide,
    replaceLogo: replaceLogo
};
