'use strict';

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
exports.init = function () {
	if ($(document).tooltip) {
		$(document).tooltip({
			items: '.tooltip',
			track: true,
			content: function () {
				var options;

				if ($(this).hasClass('tooltip--play-number')) {
					options = {
						track: false,
						tooltipClass: 'tooltip-content--play-number',
						position: {
							my: 'center-1 bottom-4',
							at: 'center top',
							collision: 'fit'
						}
					}
				} else {
					options = {
						track: true,
						tooltipClass: '',
						position: {
							my: "left top+15",
							at: "left bottom",
							collision: "flipfit"
						}
					}
				}

				$(document).tooltip(options);

				return $(this).find('.tooltip-content').html();
			},
			open: function (event, ui) {
				console.log('open', event, ui);
			}
		});
		$('body').on('click', '.close-tooltip' , function (e) {
			e.preventDefault();
			$('.tooltop').tooltip('close');
		});
		// if tooltip link is clicked, do not send user to the page
		$('body').on('click', '.pt_checkout .tooltip' , function (e) {
			e.preventDefault();
		});
        // check if tooltip is cutoff on left
		$('.play-number__label--disabled').on('click', function () {
            var offset = $('.tooltip-content--play-number').offset();
            if (offset.left < 0) {
                $('.tooltip-content--play-number').addClass('play-number-offset');
            }
		});
		$('.share-link').on('click', function (e) {
			e.preventDefault();
			var target = $(this).data('target');
			if (!target) {
				return;
			}
			$(target).toggleClass('active');
		});
	}
};
