var dialog = require('../../dialog'),
    validator = require('../../validator');

function showHidePassword() {
    $('body').on('click', '.js-password__show-hide', function () {
        $(this).toggleClass('password__show-hide--active');
        var $input = $(this).parents('.js-password').find('input');
        var type = $input.attr('type') === 'password' ? 'text' : 'password';

        $input.attr('type', type)
    });
}

function initPasswordFieldValidation() {
	$('.js-password-field').on('keyup', function () {
		var $field = $(this);

		if ($field.val().length >= 8) {
			$('.js-password__min-characters').addClass('valid');
		} else {
			$('.js-password__min-characters').removeClass('valid');
		}

		if (/[A-Z]/.test($field.val())) {
			$('.js-password__uppercase').addClass('valid');
		} else {
			$('.js-password__uppercase').removeClass('valid');
		}

		if (/[a-z]/.test($field.val())) {
			$('.js-password__lowercase').addClass('valid');
		} else {
			$('.js-password__lowercase').removeClass('valid');
		}

		if (/\d/.test($field.val())) {
			$('.js-password__number').addClass('valid');
		} else {
			$('.js-password__number').removeClass('valid');
		}
	});
}

/**
 * @private
 * @function
 * @description init events for the loginPage
 */
function initLoginPage() {
	//o-auth binding for which icon is clicked
	$('.oAuthIcon').bind('click', function () {
		$('#OAuthProvider').val(this.id);
	});

	//toggle the value of the rememberme checkbox
	$('#dwfrm_login_rememberme').bind('change', function () {
		if ($('#dwfrm_login_rememberme').attr('checked')) {
			$('#rememberme').val('true');
		} else {
			$('#rememberme').val('false');
		}
	});

	$('#password-reset, #password-reset-header').on('click', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href'),
			options: {
				dialogClass: 'password-reset-dialog',
				width: 480,
				open: function () {
					validator.init();
					var $requestPasswordForm = $('[name$="_requestpassword"]'),
						$submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
					$($submit).on('click', function (e) {
						if (!$requestPasswordForm.valid()) {
							return;
						}
						e.preventDefault();
						dialog.submit($submit.attr('name'));
					});
				}
			}
		});
	});
    $('.customer-birthday').on('click', function () {
        if ($('#dob-start').length) {
            var dob = $('#dob-start').data('dob');
            $('.customer-birthday').attr({
                'value': dob
            });
        }
	});
}

function init() {
    showHidePassword();
    initPasswordFieldValidation();
    initLoginPage();
}

module.exports = {
    init: init
};
