'use strict';
var einsteinObserver = require('./pages/product/einstein');

var headerslider = {
    init: function () {
        if ($('*[id^="cq_recomm_slot-"]').length) {
            einsteinObserver.init();
        }
    }
};

module.exports = headerslider;
