var util = require('../../../util');

module.exports = {
    init: function () {
        var $range = $('#club-slider-range');
        var $labels = $('#slider-range-labels .slider-range-label');

        var getValuesRange = function (value) {
            var min;
            var max;

            for (var i = 0; i <= attribute.options.length; i++) {
                if (value[0] === attribute.options[i]) {
                    min = i
                }

                if (value[1] === attribute.options[i]) {
                    max = i
                }
            }

            return [min, max];
        }

        var updateRangeStyles = function () {
            var $labelsWrapper = $('#slider-range-labels');
            $labelsWrapper.hide();
            var elementWidth = $range.width() / (variantsCount - 1);
            var sliderWidth = Math.ceil(elementWidth * variantsCount);
            $labelsWrapper.css('width', sliderWidth);
            $labels.css('width', elementWidth);
            $labelsWrapper.show();
            $('.slider-range-wrapper').addClass('active');
        }

        var updateRangeUI = function (id, values) {
            var $selectedAttributeValue = $('#club-range-value');
            var $input = $('#club-setcomp');
            var labelIron = $range.data('resource-iron');
            var displayValue = values[0] === values[1] ? attribute.options[values[0]] + ' ' + labelIron : attribute.options[values[0]] + '&mdash;' + attribute.options[values[1]];
            $selectedAttributeValue.html(displayValue);
            $input.val(id);
            $range.removeClass('single-active')
            $labels.removeClass('active').removeClass('single-active');

            if (values[0] === values[1]) {
                $range.addClass('single-active')
                $labels.filter('[data-position="' + values[0] + '"]').addClass('single-active');
            } else {
                $labels.filter('[data-position="' + values[0] + '"]').addClass('active');
                $labels.filter('[data-position="' + values[1] + '"]').addClass('active');
            }
        }

        if ($range.length > 0) {
            var attribute = $range.data('attribute-variants');
            var variantsCount = attribute.options.length;
            var defaultVariantID = attribute.options[0] + '-' + attribute.options[variantsCount - 1];
            var selectedVariant;
            var defaultVariant = {
                id: attribute.items[defaultVariantID].id,
                valuesRange: [0, variantsCount - 1]
            }

            for (var key in attribute.items) {
                if (attribute.items[key].isSelected) {
                    var id = key.split('-');

                    selectedVariant = {
                        id: attribute.items[key].id,
                        valuesRange: getValuesRange(id)
                    }
                }

                if (attribute.items[key].isDefault) {
                    var id = key.split('-');

                    defaultVariant = {
                        id: attribute.items[key].id,
                        valuesRange: getValuesRange(id)
                    }
                }
            }
            if (selectedVariant) {
                defaultVariant = selectedVariant
            }

            $range.slider({
                range: true,
                min: 0,
                max: variantsCount - 1,
                values: defaultVariant.valuesRange,
                create: function () {
                    // Hack to highlight left or right arrow
                    if (defaultVariant.valuesRange[0] === defaultVariant.valuesRange[1]) {
                        var middleOfSlider = Math.round((variantsCount - 1) / 2)
                        $range.find('.ui-slider-handle').removeClass('top');

                        if (defaultVariant.valuesRange[0] <= middleOfSlider) {
                            $range.find('.ui-slider-handle:last').addClass('top');

                            setTimeout(function () {
                                $range.find('.ui-slider-handle:last').addClass('hide');
                                $range.slider('values', 1, defaultVariant.valuesRange[0]);
                            }, 0);

                            setTimeout(function () {
                                $range.find('.ui-slider-handle:last').removeClass('hide').trigger('focus');
                                $range.slider('values', 1, defaultVariant.valuesRange[0]);
                            }, 5);
                        } else {
                            $range.find('.ui-slider-handle:first').addClass('top');

                            setTimeout(function () {
                                $range.find('.ui-slider-handle:first').addClass('hide');
                                $range.slider('values', 0, defaultVariant.valuesRange[0] - 1);
                            }, 0);

                            setTimeout(function () {
                                $range.find('.ui-slider-handle:first').removeClass('hide').trigger('focus');
                                $range.slider('values', 0, defaultVariant.valuesRange[0]);
                            }, 5);
                        }
                    }
                },
                slide: function (event, ui) {
                    var id = attribute.options[ui.values[0]] + '-' + attribute.options[ui.values[1]];
                    var variant = attribute.items[id];
                    $('.product-image-container').addClass('loading');

                    if (ui.values[0] === ui.values[1]) {
                        $range.find('.ui-slider-handle:first').addClass('single-active');
                    } else {
                        $range.find('.ui-slider-handle:first').removeClass('single-active');
                    }

                    // Hack to highlight left or right arrow
                    if (ui.values[0] === ui.values[1]) {
                        var middleOfSlider = Math.round((variantsCount - 1) / 2)
                        $range.find('.ui-slider-handle').removeClass('top');

                        if (ui.value <= middleOfSlider) {
                            $range.find('.ui-slider-handle:last').addClass('top');
                        } else {
                            $range.find('.ui-slider-handle:first').addClass('top');
                        }

                        if (ui.value <= middleOfSlider && $(ui.handle).index() === 1) {
                            setTimeout(function () {
                                $range.find('.ui-slider-handle:last').addClass('hide');
                                $range.slider('values', 1, ui.values[0]);
                            }, 0);

                            setTimeout(function () {
                                $range.find('.ui-slider-handle:last').removeClass('hide').trigger('focus');
                                $range.slider('values', 1, ui.values[0]);
                            }, 5);
                        } else if (ui.value > middleOfSlider && $(ui.handle).index() === 2) {
                            setTimeout(function () {
                                $range.find('.ui-slider-handle:first').addClass('hide');
                                $range.slider('values', 0, ui.values[0] - 1);
                            }, 0);

                            setTimeout(function () {
                                $range.find('.ui-slider-handle:first').removeClass('hide').trigger('focus');
                                $range.slider('values', 0, ui.values[0]);
                            }, 5);
                        }
                    }
                    updateRangeUI(variant.id, [ui.values[0], ui.values[1]]);
                },
                stop: function (event, ui) {
                    $('#configurator').trigger("sliderStop");
                }
            });
            updateRangeUI(defaultVariant.id, defaultVariant.valuesRange);
            updateRangeStyles();
            util.smartResize(updateRangeStyles);
        }
    }
}
